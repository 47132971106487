import React from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineClose } from "react-icons/ai";
import { RiDashboardFill } from "react-icons/ri";
import { GrHistory } from "react-icons/gr";
import { MdPayment } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { MdHelpCenter } from "react-icons/md";

const sidebarOptions = [
  {
    name: "dashboard",
    text: "Dashboard",
    img: <RiDashboardFill />,
  },
  {
    name: "history",
    text: "History",
    img: <GrHistory />,
  },
  {
    name: "payment",
    text: "Payment",
    img: <MdPayment />,
  },
  {
    name: "edit",
    text: "Profile",
    img: <ImProfile />,
    // text: <img src={User} alt="Logo" />,
  },
  {
    name: "Profile",
    text: "Help",
    img: <MdHelpCenter />,
  },
];

const Sidebar = ({ toggleSidebar, sidebar }) => {
  const navigate = useNavigate();

  const handleSideNavigation = (value) => {
    navigate(`/${value}`);
  };

  return (
    <div className={sidebar ? "show-sidebar" : "hide-sidebar"}>
      <div className="portioned-logo">
        <h1 onClick={() => navigate("/dashboard")} style={{ color: "blue" }}>
          PORTIONED
        </h1>
        <button onClick={toggleSidebar}>
          <AiOutlineClose
            className="closeBtn"
            style={{ fontWeight: "1000", color: "blue" }}
            size={30}
          />
          {/* <img src={Close} alt="Close" /> */}
        </button>
      </div>
      <div className="sidebar-links">
        {sidebarOptions.map((item) => (
          <p
            onClick={() => handleSideNavigation(item.name)}
            key={item.name}
            className="sidebar-link"
          >
            {item.img}
            {item.text}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
