import React from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { Footer } from "../globalcomponents/Footer";
import { Link } from "react-router-dom";
import image1 from "../assets/images/Left arrow.png";
import image2 from "../assets/images/2989096 1.png";
export const ForgotPassword = () => {
  return (
    <>
      <Navbar />
      <section className="margin">
        <div className="container">
          <div className="row LoginSection align-items-center">
            <div className="col-lg-6">
              <h3 className="main-form-title loginTitle mt-2">
                Forgot password?
              </h3>
              <p className="py-2">
                No worries, we’ll send you reset instructions.
              </p>
              <form className="form mainForm loginForm" action="#">
                <div className="mb-3">
                  <label
                    htmlfor="formGroupExampleInput2"
                    className="form-label"
                  >
                    <p>Email Address</p>
                  </label>
                  <input
                    type="email"
                    className="form-control customInput p-2 my-2 borderForm"
                    id="formGroupExampleInput2"
                    placeholder="janedoe@gmail.com"
                  />
                </div>
              </form>
              <div className="btn-padding ">
                <Link
                  to="/newpassword"
                  className="btn text-decoration-none text-white px-4 py-3 bg-primary"
                >
                  Reset
                </Link>
              </div>
              <div className="d-flex align-items-center py-3">
                <img className="" src={image1} alt="" />
                <Link
                  to="/login"
                  className="text-decoration-none text-dark px-2"
                >
                  Back to log in.
                </Link>
                <p />
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="mb-lg-0 mb-sm-3 mb-3 imgWidth"
                width="75%"
                src={image2}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
