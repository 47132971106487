import React from "react";
import blog1 from "../../assets/images/blog-1.png";
import blog2 from "../../assets/images/blog-2.png";
import blog3 from "../../assets/images/blog-3.png";
import { Link, useNavigate } from "react-router-dom";
export const BlogCard = () => {
  const navigate = useNavigate();
  const blogCards = [
    {
      Img: blog1,
      text: "How to Properly Package Consignment for Ocean Freight",
    },
    {
      Img: blog2,
      text: "The Shipper and Government Agencies",
    },
    {
      Img: blog3,
      text: "Export Financing in Nigeria",
    },
  ];

  const handleClick = (text) => {
    navigate("/blogdetails", { state: text });
  };

  return (
    <>
      {blogCards.map((card) => {
        return (
          <div
            key={card.text}
            className="col-md-4 mb-lg-0 mb-sm-4 mb-4 cursor-pointer"
            onClick={() => handleClick(card.text)}
          >
            <img src={card.Img} className="w-100 mb-4 cursor-pointer" />
            <p>{card.text}</p>
          </div>
        );
      })}
      {/* <div className="col-md-4 mb-lg-0 mb-sm-4 mb-4">
        <Link to="/blogdetails1" className="text-decoration-none text-dark">
          <img src={blog1} className="w-100 mb-4" alt="" />

          <p>How to Properly Package Consignment for Ocean Freight</p>
        </Link>
      </div>
      <div className="col-md-4 mb-lg-0 mb-sm-4 mb-4">
        <Link to="/blogdetails2" className="text-decoration-none text-dark">
          <img src={blog2} className="w-100 mb-4" alt="" />

          <p>The Shipper and Government Agencies</p>
        </Link>
      </div>

      <div className="col-md-4 mb-lg-0 mb-sm-4 mb-4">
        <Link to="/blogdetails3" className="text-decoration-none text-dark">
          <img src={blog3} className="w-100 mb-4" alt="" />

          <p>Export Financing in Nigeria</p>
        </Link>
      </div> */}
    </>
  );
};
