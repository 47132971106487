import { createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../Utils/UtilityFunctions";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  // user: getUserFromLocalStorage(),
  payload: {
    bookingCode: "",
    destinationPort: "",
    depatureDate: "",
    description: "",
    category: "",
    deliveryOption: "",
    deliveryDate: "",
    delivered: false,
    madePayment: false,
    packaging: "",
    hazardous: "",
    bonded: "",
    worth: "",
    size: "",
    timeStamp: "",
    img: [],
  },
  step: "deliveryOption",
};
const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.Loading = false;
    },
    logout: (state) => {
      state.user = null;
      state.Loading = false;
    },
    next: (state, action) => {
      const { step, payload } = action.payload;
      state.payload = payload;
      state.step = step;
    },
    reset: (state) => {
      state.payload = initialState.payload;
      state.step = initialState.step;
    },
  },
});

export const { login, logout, next, reset } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
