import React from "react";

const FormSelect = ({
  labelText,
  name,
  required,
  value,
  handleChange,
  list,
}) => {
  return (
    <div className="-input">
      <label htmlFor={name} style={{ paddingBottom: "1rem" }}>
        {labelText || name}
      </label>
      <select
        name={name}
        value={value}
        id={name}
        required={required}
        onChange={handleChange}
        className="form-select"
      >
        {list.map((itemValue, index) => {
          return (
            <option key={index} value={itemValue}>
              {itemValue}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default FormSelect;
