import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { db, storage } from "../Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { next, selectUser } from "../Features/User/UserSlice";
import { toast } from "react-toastify";
import Portioned from "./Assets/PortionedLogo.svg";
import { BiArrowBack } from "react-icons/bi";

const ConsignmentDocument = () => {
  const { step } = useSelector((state) => state.user);
  const payload = JSON.parse(localStorage.getItem("payload"));

  const defaultValues = {
    council: "",
    incoporation: "",
    invoice: "",
    parkinglist: "",
  };
  const [files, setFiles] = useState(defaultValues);
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [fileURL, setFileUrL] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let imgURL = [];

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.files[0];
    setFiles({ ...files, [name]: value });
  };

  const disabled =
    !files.council &&
    !files.incoporation &&
    !files.invoice &&
    !files.parkinglist;

  const fileToUpload = Object.keys(files).map((key) => files[key]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      fileToUpload.forEach(async (file) => {
        if (file.name) {
          const name = new Date().getTime() + file.name;

          const fileFolderRef = ref(storage, `projectFiles/${name}`);

          const uploadTask = uploadBytesResumable(fileFolderRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // const progress =
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case "paused":
                  // console.log("Upload is paused");
                  break;
                case "running":
                  // console.log("Upload is running");
                  break;
                default:
                  break;
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              toast.error(error.message);
            },
            () => {
              // Handle successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  // const url = {
                  //   downloadURL,
                  // };

                  storeImage(downloadURL);

                  // console.log(imgURL);

                  // const booking = {
                  //   [bookingCode]: {
                  //     [file.name]: downloadURL,
                  //     madePayment: false,
                  //     deliveryDate: "",
                  //   },
                  // };
                  // await setDoc(
                  //   doc(db, "user", user.uuid),

                  // {
                  //   [file.name]: downloadURL,
                  // }
                  // // booking,
                  // {
                  //   merge: true,
                  // }
                  // );
                  // console.log("File available at", downloadURL);
                }
              );
            }
          );
        }
      });

      const storeImage = async (url) => {
        imgURL.push(url);
        const updatedData = { ...payload, img: imgURL, timeStamp: Date.now() };
        localStorage.setItem("payload", JSON.stringify(updatedData));
      };

      setLoading(false);
      toast.success("Upload done successfully");

      setTimeout(() => {
        dispatch(
          next({
            step: "summary",
          })
        );
      }, 4000);
    } catch (error) {
      toast.error("Image not uploaded");
    }
  };

  return (
    <section className="consignment-page">
      {/* <div className="page-content"> */}
      <div className="shipment-header">
        <Link to="/dashboard">
          <h1 className="portined-logo">PORTIONED</h1>

          <img src={Portioned} alt="Logo" width="50rem" />
        </Link>
        <h2>Documents</h2>
      </div>

      <div className="shipment-info">
        <div className="consignmentdetails-info-right">
          <div className="header-back">
            <BiArrowBack
              size={30}
              className="icon"
              onClick={() => navigate("/consignmentdetails")}
            />
            <h1>Consignment documents</h1>
          </div>

          <p>Please, upload the necessary documents for your consignment.</p>

          <form onSubmit={handleSubmit} className="shipment-form-input">
            <label htmlFor="council" style={{ fontWeight: "500" }}>
              Nigerian Export Promotion Council Certificate
            </label>
            <input
              className="shipment-input upload-input"
              type="file"
              name="council"
              required
              onChange={handleChange}
            />
            <label htmlFor=" incoporation" style={{ fontWeight: "500" }}>
              Certificate of Incorporation
            </label>
            <input
              className="shipment-input upload-input"
              type="file"
              required
              name="incoporation"
              onChange={handleChange}
            />
            <label htmlFor=" invoice" style={{ fontWeight: "500" }}>
              Final Invoice
            </label>
            <input
              className="shipment-input upload-input"
              type="file"
              name="invoice"
              required
              onChange={handleChange}
            />
            <label htmlFor="parkinglist" style={{ fontWeight: "500" }}>
              Packing List
            </label>
            <input
              className="shipment-input upload-input "
              type="file"
              name="parkinglist"
              required
              onChange={handleChange}
            />
            <button
              type="submit"
              disabled={disabled}
              className={disabled ? "disable-btn submit-btn" : "submit-btn"}
            >
              {loading ? "Loading" : "Continue"}
            </button>
          </form>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default ConsignmentDocument;
