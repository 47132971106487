import React from "react";
import "./Tablestyles.css";

const Table = ({ tableData }) => {
  return (
    <div className="table-wrapper">
      <div className="table-header">
        <h3>Billing History</h3>
      </div>
      <div className="table-container">
        <table>
          {/* Table headers */}
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Description</th>
              <th>Date</th>
              <th>Status</th>
              <th>Amount(Naira)</th>
            </tr>
          </thead>

          {/* Table body */}
          <tbody>
            {/* Map through the data to render table rows */}
            {tableData.map((item, index) => {
              if (item?.madePayment === true) {
                return (
                  <tr key={index} className="data">
                    <td>{index}</td>
                    <td data-cell="Transaction ID">{item.bookingCode}</td>
                    <td data-cell="Description">{item.description}</td>
                    <td data-cell="Date">{item.depatureDate}</td>
                    <td data-cell="Status">Paid</td>
                    <td data-cell="Amount">{item.worth}</td>
                    {/* <td>{item.column5}</td> */}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
