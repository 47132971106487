import React from "react";
import { MdDone } from "react-icons/md";
import "./SuccessStyles.css";
import { Link, useNavigate } from "react-router-dom";
import Portioned from "../Assets/PortionedLogo.svg";
import { useDispatch } from "react-redux";
import { reset } from "../../Features/User/UserSlice";

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="success-contianer">
      <div className="shipment-header">
        <Link to="/dashboard">
          <h1
            onClick={() => {
              navigate("/dashboard");
            }}
            className="portined-logo"
          >
            PORTIONED
          </h1>
          <img src={Portioned} alt="Logo" width="50rem" />
        </Link>
        <h2>Success</h2>
      </div>
      <div className="success-card">
        <MdDone size={40} className="done-icon" />
        <p className="success-text">
          You have successfully completed your order
        </p>
        <div className="success-desc">
          <p>
            Our team is currently processing your information, and you can
            expect to receive a detailed quote in your email shortly. If you
            have any further questions or need immediate assistance, please feel
            free to contact us at +234 811 692 2792. Thank you for choosing
            Portioned.
          </p>
        </div>
        <button
          onClick={() => {
            dispatch(reset());
            navigate("/dashboard");
          }}
          className="success-btn"
        >
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default Success;
