import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // // user: JSON.parse(localStorage.getItem("user")) || null,
  // user: getUserFromLocalStorage(),
  Loading: false,
  path: "",
};
const profileSlice = createSlice({
  name: "profile",
  initialState,

  reducers: {},
});

export const { Loading } = profileSlice.actions;
export const selectPath = (state) => state.profile.path;
export default profileSlice.reducer;
