import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import plus from "./Assets/plus.svg";
import Sidebar from "../globalcomponents/Sidebar";
import { selectUser } from "../Features/User/UserSlice";
import { useSelector } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { Footer } from "../globalcomponents/Footer";
import Loading from "../globalcomponents/Loading";
import { toast } from "react-toastify";
import Navbar from "../components/Dashboard/Navbar";

const Dashboard = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const currentUser = useSelector(selectUser);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const getUserDetails = async () => {
    const userCollection = doc(db, "user", currentUser.email);

    try {
      setLoading(true);

      const response = await getDoc(userCollection);
      const data = response.data();

      setUserData(data);
      setLoading(false);
    } catch (error) {
      toast.error("There was an error");
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="dashboard">
      <div className="dashboard-main">
        <Sidebar sidebar={sidebar} toggleSidebar={toggleSidebar} />
        <div className="dashboard-right">
          {/* <div className="right-content" style={{ height: "100vh" }}> */}
          <Navbar userData={userData} toggleSidebar={toggleSidebar} />

          <div className="dashboard-options">
            <div className="dashboard-option">
              <div
                className="dashboard-add-shipment"
                onClick={() => navigate("/addshipment")}
              >
                <h4>Add a new shipment</h4>
                <small>
                  Fill out this form to secure a slot for your consignment!
                </small>
                <img src={plus} alt="plus" />
              </div>
            </div>
            <div className="dashboard-option">
              <div className="dashboard-tracker">
                <h4> Track your shipment</h4>
                <small>
                  To view the live movement of your consignment, enter your
                  tracking ID
                </small>
                <input
                  type="text"
                  name="trackID"
                  placeholder="Enter Tracking ID"
                  className="tracking-id"
                />
                <button>Track</button>
              </div>
            </div>
            {/* <div className="dashboard-option">
                <div className="dashboard-lcl-rate">
                  <h4> Today's LCL's shipping rate</h4>
                  <p>maersk 27-32 Days </p>
                  <p>Sailing: flexible Date</p>
                  <p>Route :TimCan port to Rotterdam port</p>
                  <p>Port to Port transit time:10- 20 days</p>
                  <p>Valid until 20th March 2023</p>
                  <div className="price-rating">
                    <p>Price:$70,000</p>
                    <p>Rating:</p>
                  </div>
                  <div className="dashboard-select-btn">
                    <button>Select</button>
                    <button>Next</button>
                  </div>
                </div>
              </div> */}
            {/* <div className="dashboard-option">
                <div className="dashboard-lcl-rate">
                  <h4> Today’s FCL Shipping Rates</h4>
                  <p> 27-32 Days </p>
                  <p>Sailing: flexible Date</p>
                  <p>Route :TimCan port to Rotterdam port</p>
                  <p>Port to Port transit time:10- 20 days</p>
                  <p>Valid until 20th March 2023</p>
                  <div className="price-rating">
                    <p>Price:$70,000</p>
                    <p>Rating:</p>
                  </div>
                  <div className="dashboard-select-btn">
                    <button>Select</button>
                    <button>Next</button>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
        {/* </div> */}
      </div>
      <Footer />
    </section>
  );
};

export default Dashboard;
