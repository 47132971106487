import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { selectUser } from "../../Features/User/UserSlice";
import { useSelector } from "react-redux";

export const Hero = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section>
        <div className="container main-section">
          <div className="text-center">
            <h2 className="main-title">
              Shipping Solution for Nigerian Shippers
            </h2>
            <p className="main-content">
              Portion eliminates the stress of the shipper by manoeuvring common
              setbacks, mistakes and delays, erasing losses, and the general
              stress involved in shipping in Nigeria.
            </p>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <div className="btn-padding bg-primary">
              <Link
                to={"/login"}
                className="btn h-100 d-flex align-items-center text-decoration-none text-white px-4 py-3"
              >
                Get started
              </Link>
            </div>
            {/* <div className="btn-padding ms-2 blue-border">
              <Link to="/login" className="btn text-decoration-none px-4 py-3">
                Track Shipment
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
