import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
export const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMobileMenu = () => {
    setIsActive(!isActive);
  };

  const closeMenu = () => {
    setIsActive(false);
  };
  return (
    <header className="header mainPadding" id="navigation-menu">
      <nav className="navbar pt-3">
        <Link
          to="/"
          className="nav-logo js-anchor-link text-decoration-none text-black"
        >
          PORTIONED
        </Link>
        <ul className={`nav-menu ${isActive ? "active" : ""}`}>
          <li className="nav-item">
            <Link
              to="/about"
              className="nav-link js-anchor-link text-decoration-none"
            >
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/resources"
              className="nav-link js-anchor-link text-decoration-none"
            >
              Resources
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/login"
              className="nav-link js-anchor-link text-decoration-none"
            >
              LOG IN
            </Link>
          </li>
          <li className="nav-item btn-padding">
            <Link
              to="/signup"
              className="btn text-decoration-none bg-primary text-white nav-link js-anchor-link py-2 px-3"
            >
              SIGN UP
            </Link>
          </li>
        </ul>
        <div
          className={`hamburger ${isActive ? "active" : ""}`}
          onClick={toggleMobileMenu}
        >
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </div>
      </nav>
    </header>
  );
};
