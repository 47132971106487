import React from "react";
import { Link } from "react-router-dom";

export const StartShiping = () => {
  return (
    <section className="margin mainPadding">
      <div className="mainPadding bg-light p-lg-5 p-sm-3 p-3 shippingBtn">
        <p className="shippingTitle mb-4">Start shipping now!</p>
        <div className=" btn-padding btnMargin">
          <Link
            to="/login"
            className="text-decoration-none bg-primary text-white shippingBtn"
          >
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
};
