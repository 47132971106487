import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../Firebase";
import { Navbar } from "../globalcomponents/Navbar";
// import { Hero } from "../components/Home/Hero";
import imagelogin from "../assets/images/bwink_bld_03_single_03 1.png";
import { Footer } from "../globalcomponents/Footer";
import { toast } from "react-toastify";
import { addUserToLocalStorage } from "../Utils/UtilityFunctions";
import { selectUser } from "../Features/User/UserSlice";
import { useSelector } from "react-redux";
export const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const defaultValues = {
    email: "",
    password: "",
  };
  const [values, setValues] = useState(defaultValues);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = values;
    if (email && password) {
      try {
        const authUser = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        toast.success("Logged in Successfully");

        addUserToLocalStorage(user);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } catch (error) {
        toast.error("Invalid Username/Password");
      }
    } else {
      toast.error("Please Input Email/Password");
    }
  };

  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="row LoginSection align-items-center">
          <div className="col-lg-6">
            <h3 className="main-form-title loginTitle">Welcome back!</h3>
            <p className="py-2">
              Hey, enter your details to sign in to your account.
            </p>
            <form
              onSubmit={handleSubmit}
              className="form mainForm loginForm"
              action="#"
            >
              <div className="mb-3 mt-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control customInput p-2 my-2 borderForm"
                  id="formGroupExampleInput"
                  placeholder="janedoe@gmail.com"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="formGroupExampleInput2"
                  className="form-label d-flex justify-content-between"
                >
                  <p>Password</p>
                  <Link
                    to="/forgotpassword"
                    className="text-primary text-decoration-none"
                  >
                    Forgot Password?
                  </Link>
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control customInput p-2 my-2 borderForm"
                  id="formGroupExampleInput2"
                  placeholder="******"
                  value={values.password}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <button className="btn text-decoration-none  text-white btn-padding px-4 py-3 bg-primary wfc">
                  Log in
                </button>
              </div>
            </form>
            <p>
              Don’t have an account? &nbsp;
              <Link
                to="/signup"
                className="text-decoration-none border-0 bg-transparent text-primary"
              >
                Sign up
              </Link>
            </p>
          </div>
          <div className="col-lg-6">
            <img className="w-lg-auto w-100 w-sm-100" src={imagelogin} alt="" />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
