import React from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { Hero } from "../components/Home/Hero";
import imageSignup from "../assets/images/Rectangle 84.png";
import { Footer } from "../globalcomponents/Footer";
import { Link } from "react-router-dom";
import image1 from "../assets/images/5500661 1.png";
import image2 from "../assets/images/Left arrow.png";
export const NewPassword = () => {
  return (
    <>
      <Navbar />

      <section className="margin">
        <div className="container">
          <div className="row LoginSection align-items-center">
            <div className="col-lg-6">
              <h3 className="main-form-title loginTitle mt-2">
                Set new password
              </h3>
              <p className="py-2">Must be at least 8 characters.</p>
              <form className="form mainForm loginForm" action="#">
                <div className="mb-3">
                  <label
                    htmlfor="formGroupExampleInput2"
                    className="form-label"
                  >
                    <p>New Password</p>
                  </label>
                  <input
                    type="password"
                    className="form-control customInput p-2 my-2 borderForm"
                    id="formGroupExampleInput2"
                    placeholder="******"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlfor="formGroupExampleInput2"
                    className="form-label"
                  >
                    <p>Confirm new password</p>
                  </label>
                  <input
                    type="password"
                    className="form-control customInput p-2 my-2 borderForm"
                    id="formGroupExampleInput2"
                    placeholder="******"
                  />
                </div>
                <div className="btn-padding ">
                  <Link
                    to="/passwordreset"
                    className="btn text-decoration-none text-white px-4 py-3 bg-primary"
                  >
                    Reset
                  </Link>
                </div>
                <div className="d-flex align-items-center py-3">
                  <img className="" src={image2} alt="" />

                  <Link
                    to="/login"
                    className="text-decoration-none bg-transparent border-0 text-dark px-2"
                  >
                    Back to log in.
                  </Link>

                  <p />
                </div>
              </form>
            </div>
            <div className="col-lg-6">
              <img
                className="mb-lg-0 mb-sm-3 mb-3 imgWidth"
                width="75%"
                src={image1}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
