import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Features/User/UserSlice";
import profileReducer from "./pages/ProfilePage/Slice/ProfileSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    profile: profileReducer,
  },
});
