import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { useDispatch, useSelector } from "react-redux";
import { next, selectUser } from "../Features/User/UserSlice";
import { toast } from "react-toastify";
import Portioned from "./Assets/PortionedLogo.svg";
import Loading from "../globalcomponents/Loading";
import { BiArrowBack } from "react-icons/bi";
// import FormSelect from "../globalcomponents/FormSelect";
// import { listOfPorts } from "../Utils/ListofPorts";

const DeliveryOption = () => {
  const defaultValues = {
    destinationPort: "",
    depatureDate: "",
    description: "",
  };
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [, setUserData] = useState([]);
  const currentUser = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { payload } = useSelector((state) => state.user);
  // const user = useSelector(selectUser);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const disabled =
    !values.destination && !values.depatureDate && !values.description;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Generate a random string of alphanumeric characters
    const bookingCode = Math.random()
      .toString(36)
      .substring(2, 8)
      .toUpperCase();

    // const booking = { [bookingCode]: { ...values, bookingCode } };
    try {
      setLoading(true);
      const updatedData = { bookingCode, ...payload, ...values };
      dispatch(
        next({
          step: "consignmentDetails",
          payload: { ...updatedData },
        })
      );

      localStorage.setItem("payload", JSON.stringify(updatedData));

      toast.success("saved successfully");
    } catch (error) {}
    setLoading(false);
  };

  const getUserDetails = async () => {
    const userCollection = doc(db, "user", currentUser.email);

    try {
      setLoading(true);

      const response = await getDoc(userCollection);
      const data = response.data();

      setUserData(data);
      setLoading(false);
    } catch (error) {
      toast.error("There was an error");
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="shipment-page">
      <div className="shipment-header">
        <Link to="/dashboard">
          <h1 className="portined-logo">PORTIONED</h1>
          <img src={Portioned} alt="Logo" width="50rem" />
        </Link>
        <h2>Add a Shipment</h2>
      </div>

      <div className="shipment-info">
        <div className="shipment-info-right">
          <div className="header-back">
            <BiArrowBack
              size={30}
              className="icon"
              onClick={() => navigate("/dashboard")}
            />
            <h1>Delivery options</h1>
          </div>

          <p>Choose the delivery options that best fit your consignment</p>

          <form onSubmit={handleSubmit} className="shipment-form-input">
            <label htmlFor="calender">Destination ports </label>
            <input
              className="shipment-input"
              type="text"
              name="destinationPort"
              required
              placeholder="Destination"
              value={values.destinationPort}
              onChange={handleChange}
            />
            {/* <FormSelect
              name="destination"
              labelText="List of destination ports"
              value={values.destination}
              handleChange={handleChange}
              list={listOfPorts}
            /> */}

            <label htmlFor="calender">Preferred date for departure</label>
            <input
              className="shipment-input"
              type="date"
              name="depatureDate"
              required
              placeholder="Calendar"
              value={values.depatureDate}
              onChange={handleChange}
            />

            <label htmlFor="description">
              Please provide any special instruction
            </label>
            <textarea
              className="shipment-input"
              type="text"
              name="description"
              required
              onChange={handleChange}
              placeholder="Instructions"
              value={values.description}
            />
            <button
              type="submit"
              className={disabled ? "disable-btn submit-btn" : "submit-btn"}
              disabled={disabled}
            >
              {loading ? "Loading..." : "Continue"}
            </button>
          </form>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default DeliveryOption;
