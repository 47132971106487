import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { next, selectUser } from "../Features/User/UserSlice";
import Portioned from "./Assets/PortionedLogo.svg";
import { BiArrowBack } from "react-icons/bi";
import FormSelect from "../globalcomponents/FormSelect";

const ConsignmentDetails = () => {
  const navigate = useNavigate();
  const { step } = useSelector((state) => state.user);
  const payload = JSON.parse(localStorage.getItem("payload"));
  const defaultValues = {
    category: "",
    location: "",
    packaging: "Yes",
    hazardous: "Yes",
    bonded: "Yes",
    deliveryOption: "Pick up",
    worth: "",
    size: "20ft",
  };
  const [values, setValues] = useState(defaultValues);

  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const { state: bookingCode } = location;

  const disabled =
    !values.category &&
    !values.location &&
    !values.packaging &&
    !values.hazardous &&
    !values.bonded &&
    !values.deliveryOption;

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const booking = { [bookingCode]: { ...values } };
    const updatedData = { ...payload, ...values };

    try {
      //Get all the user Docs
      setLoading(true);
      dispatch(
        next({
          step: "consignmentDocument",
          payload: {
            ...updatedData,
          },
        })
      );

      localStorage.setItem("payload", JSON.stringify(updatedData));

      toast.success("saved successfully");
    } catch (error) {}
    setLoading(false);
  };

  return (
    <section className="consignment-page">
      {/* <div className="page-content"> */}
      <div className="shipment-header">
        <Link to="/dashboard">
          <h1 className="portined-logo">PORTIONED</h1>
          <img src={Portioned} alt="Logo" width="50rem" />
        </Link>
        <h2>Consignment Details</h2>
      </div>

      <div className="consignment-info">
        <div className="consignmentdetails-info-right">
          <div className="header-back">
            <BiArrowBack
              size={30}
              className="icon"
              onClick={() => navigate("/deliveryoptions")}
            />
            <h1>Consignment Information</h1>
          </div>

          <p style={{ paddingBottom: "1rem" }}>
            Add the necessary information of your shipment
          </p>

          <form onSubmit={handleSubmit} className="shipment-form-input">
            <label htmlFor="category">Consignment Category</label>
            <input
              className="shipment-input"
              type="text"
              name="category"
              required
              onChange={handleChange}
              placeholder="Category"
              value={values.category}
            />
            {/* <label htmlFor="deliveryOption">Delivery Option</label> */}
            <FormSelect
              name="deliveryOption"
              labelText="Delivery option"
              value={values.deliveryOption}
              required={true}
              handleChange={handleChange}
              list={["Pick up", "Drop off"]}
            />
            <p className="delivery-option-text">
              *Pick up option from your warehouse attracts a fee depending on
              your location.
            </p>

            <label htmlFor=" location">Pick up location</label>
            <input
              className="shipment-input"
              type="text"
              name="location"
              placeholder="Pick up location"
              value={values.location}
              onChange={handleChange}
            />

            <FormSelect
              name="packaging"
              labelText="
              Will you require us to package your consignment?"
              value={values.packaging}
              handleChange={handleChange}
              list={["Yes", "No"]}
            />

            <FormSelect
              name="hazardous"
              labelText="Hazardous"
              value={values.hazardous}
              handleChange={handleChange}
              list={["Yes", "No"]}
            />

            <FormSelect
              name="bonded"
              labelText="Bonded"
              value={values.bonded}
              handleChange={handleChange}
              list={["Yes", "No"]}
            />
            <label htmlFor=" location">
              Value (Worth of consignment) in Naira
            </label>
            <input
              className="shipment-input"
              type="text"
              name="worth"
              placeholder="worth"
              required
              value={values.worth}
              onChange={handleChange}
            />
            <FormSelect
              name="size"
              labelText="Size"
              value={values.size}
              handleChange={handleChange}
              list={["20ft", "40ft", "45ft"]}
            />

            <button
              type="submit"
              disabled-={disabled}
              className={disabled ? "disable-btn submit-btn" : "submit-btn"}
            >
              {loading ? "Loading..." : " Continue"}
            </button>
          </form>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default ConsignmentDetails;
