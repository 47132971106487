import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { laterPost } from "../../Utils/ProfileDate";

export const LaterPosts = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleView = (id) => {
    const exist = laterPost.find((post) => post.header === id);

    navigate(`/posts`, { state: { ...exist } });
  };
  return (
    <>
      <div className="margin">
        <h4 className="main-form-title loginTitle mt-2 mb-2">Later posts</h4>
        {laterPost.map((post) => (
          <div className="border-bottom py-4 " key={post.header}>
            <div
              onClick={() => handleView(post.header)}
              className="text-lg "
              style={{ cursor: "pointer" }}
            >
              {post.header}
            </div>
            <p className="mb-0 pt-3 text-sm light">{post.date}</p>
          </div>
        ))}
      </div>
      {/* <div className="margin">
        <h4 className="main-form-title loginTitle mt-2 mb-4" onClick={() => {}}>
          Later posts
        </h4>
        <div className="border-bottom py-4">
          <div className="text-lg">Am I Ready to Export?</div>
          <p className="mb-0 pt-3 text-sm light">17 February, 2023</p>
        </div>
        <div className="border-bottom py-4">
          <div className="text-lg">
            Interesting Facts About Shipping Transportation
          </div>
          <p className="mb-0 pt-3 text-sm light">27 February, 2023</p>
        </div>
        <div className="border-bottom py-4">
          <div className="text-lg">The Wealth of Nigerian Resources</div>
          <p className="mb-0 pt-3 text-sm light">07 March, 2023</p>
        </div> */}
      {/* <div className="border-bottom py-4">
                    <div className="text-lg">Export Financing</div>
                    <p className="mb-0 pt-3 text-sm light">17 February, 2023</p>
                </div>
                <div className="border-bottom py-4">
                    <div className="text-lg">Export Financing</div>
                    <p className="mb-0 pt-3 text-sm light">17 February, 2023</p>
                </div>
                <div className="border-bottom py-4">
                    <div className="text-lg">Export Financing</div>
                    <p className="mb-0 pt-3 text-sm light">17 February, 2023</p>
                </div>
                <div className="border-bottom py-4">
                    <div className="text-lg">Export Financing</div>
                    <p className="mb-0 pt-3 text-sm light">17 February, 2023</p>
                </div> */}
      {/* </div> */}
    </>
  );
};
