import React from "react";

const Features = () => {
  return (
    <div className="portion-feature">
      <div className="price-estimate">
        <h4> How to add a new shipment:</h4>
        <p>
          Adding a new shipment to Portioned is a straightforward process. Here
          are the basic steps to follow:
        </p>
        <p>
          1. Log in to your account: Access your account on Portioned by
          providing your login details.
        </p>
        <p>
          2. Select the option to add a new shipment: Once you are logged in,
          locate the "add a new shipment" button or option on the app's
          dashboard or main menu.
        </p>
        <p>
          3. Enter shipment details: Fill in the required fields such as the
          shipment's origin, destination, weight, dimensions, and any other
          relevant details such as type of goods, packaging, and special
          instructions.
        </p>
        <p>
          4. Choose shipping method and carrier: Select the shipping method and
          carrier that you prefer for your shipment. You may also compare
          different options provided by Portioned to select the one that best
          suits your needs.
        </p>
        <p>
          5. Confirm and submit the shipment: Review the shipment details for
          accuracy and completeness. Once you are satisfied, click on the
          "submit" button to add the new shipment to your account.
        </p>
        <p>
          6. Make payment: Depending on Portioned, you may need to make payment
          for the shipment before it can be processed. Payment options may
          include credit card, bank transfer, or other payment methods supported
          by the app.
        </p>
        <p>
          7. Track the shipment: After the shipment has been added to Portioned,
          you can track its progress and receive updates on its status until it
          is delivered to its destination.
        </p>
        <p>
          Adding a new shipment involves logging in to your account, entering
          the shipment details, selecting the carrier and shipping method,
          confirming the details, making payment, and tracking the shipment.
          With these simple steps, you can easily and conveniently manage your
          shipment using a freight forwarding app.
        </p>
        <h4>How to get a price estimate</h4>
        <p>
          Getting a price estimate for a shipment on Portioned is a
          straightforward process. By entering “Port of Origin”, “Port of
          Destination” and “Weight” of your consignment on the homepage of
          Portioned website, you can get a price estimate for your shipments.
        </p>
        <p>
          How to sign up/log in If you're interested in using 'Portioned',
          here's a step-by-step guide on how to sign up or log in to your
          account: To
        </p>
        <h4>Sign Up:</h4>
        <p>
          1. Go to the Portioned website: Open your preferred web browser and go
          to the Portioned website at www.myportioned.com.
        </p>
        <p>
          {" "}
          2. Click on "Sign Up": On the homepage, click on the "Sign Up" button
          located in the top right-hand corner.
        </p>
        <p>
          3. Enter your details: Fill in your name, email address, and a secure
          password to create your account.
        </p>
        <p>
          4. Verify your email: Portioned will send you an email to verify your
          email address. Click on the link in the email to confirm your account.
        </p>
        <p>
          5. Complete your profile: Once your email is verified, complete your
          profile by entering your company name, phone number, and other
          necessary details.
        </p>
        <p>
          6. Start using the web-app: Once your profile is complete, you can
          start using the web-app to manage your ocean freight forwarding needs.
        </p>
        <h4> To Log In:</h4>
        <p>
          1. Go to the Portioned website: Open your preferred web browser and go
          to the Portioned website at www.myportioned.com.
        </p>
        <p>
          2. Click on "Log In": On the homepage, click on the "Log In" button
          located in the top right-hand corner.
        </p>
        <p>
          3. Enter your details: Enter your email address and password to access
          your account.
        </p>
        <p>
          4. Start using the web-app: Once you're logged in, you can start using
          the web-app to manage your ocean freight forwarding needs.
        </p>
        <p>
          Signing up or logging in to the Portioned web-app is a simple process
          that involves going to the website, filling in your details, verifying
          your email, and completing your profile. By following these steps, you
          can easily and conveniently access the web-app and manage your
          logistics and supply chain needs.
        </p>
      </div>
    </div>
  );
};

export default Features;
