import React from "react";

import { useLocation } from "react-router-dom";
import AboutPortioned from "./AboutPortioned";
import Features from "./Features";
import AccountData from "./AccountData";
import Resources from "./Resources";
import UsingPortioned from "./UsingPortioned";
import Help from "./Help";

const MoreInfo = () => {
  const location = useLocation();
  const { state: item } = location;

  function renderComponent(caseValue) {
    switch (caseValue) {
      case "About Portioned":
        return <AboutPortioned />;
      case "Features":
        return <Features />;
      case "Account and data":
        return <AccountData />;
      case "Using Portioned":
        return <UsingPortioned />;
      case "Resources":
        return <Resources />;
      case "Help":
        return <Help />;
      default:
        return;
    }
  }
  const render = renderComponent(item);
  return <div className="more-info">{render}</div>;
};

export default MoreInfo;
