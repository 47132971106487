import React from 'react'
import image from '../../assets/images/Rectangle 88.png'
export const WhyChoose = () => {
  return (
    <section className="margin">
    <h1 className="chooseTitle text-center mb-5">Why choose Portioned?</h1>
    <div className="mainPadding">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <p className="chooseContent px-lg-5 px-sm-3 my-2">
            Portioned offers visible and efficient shipping process
          </p>
          <img
            className="w-lg-auto w-100 w-sm-100"
            src={image}
            alt=""
          />
        </div>
        <div className="col-lg-6">
          <div className="mb-4">
            <h3 className="chooseContent">Perfect for medium scale shippers</h3>
            <p className="chooseCont">
              Can’t meet up with the cargo requirements to send your consignment?
              We have you covered! Portioned offers groupage solutions, all at
              your fingertips.
            </p>
          </div>
          <div className="">
            <h3 className="chooseContent">Perfect for first time shippers</h3>
            <p className="chooseCont">
              First time sending a consignment? Portioned is perfect for your
              shipping procedures.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  

  )
}
