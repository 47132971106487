import React from 'react'
import { Navbar } from '../globalcomponents/Navbar'
import { Footer } from '../globalcomponents/Footer'
import fb from '../assets/images/Facebook circular logo.png'
import phone from '../assets/images/Phone receiver silhouette.png'
import watsapp from '../assets/images/Whatsapp.png'
import insta from '../assets/images/Instagram.png'
import linkdin from '../assets/images/Linkedin.png'
import email from '../assets/images/Email.png'
export const ComingSoon = () => {
    return (
        <>
            <Navbar />

            <section className="margin">
                <div className="container">
                    <h2 className="text-center py-2 ComingTitle">
                        Our Shipping Solution will help you
                    </h2>
                    <h4 className="text-center py-2 growTitle">Grow your business:</h4>
                    <h5 className="text-center py-2 getTitle">
                        Get more{" "}
                        <a href="#" className="text-decoration-none text-primary">
                            Customers
                        </a>
                    </h5>
                    <h2 className="text-center py-2 ComingTitle text-primary">
                        From the comfort of your home.
                    </h2>
                    <section className="container margin mb-5">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <h4 className="main-form-title">Get notified when we launch</h4>
                                <form className="form mainForm" action="#">
                                    <div className="mb-3 mt-3">
                                        <label htmlfor="formGroupExampleInput" className="form-label">
                                            Name
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control customInput p-2 my-3 borderForm"
                                            id="formGroupExampleInput"
                                            placeholder="John Doe"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlfor="formGroupExampleInput2" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            required
                                            type="email"
                                            className="form-control customInput p-2 my-3 borderForm"
                                            id="formGroupExampleInput2"
                                            placeholder="Enter Your Email"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlfor="formGroupExampleInput2" className="form-label">
                                            Phone
                                        </label>
                                        <input
                                            required
                                            type="tel"
                                            className="form-control customInput p-2 my-3 borderForm"
                                            id="formGroupExampleInput2"
                                            placeholder='09099992222'
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlfor="formGroupExampleInput" className="form-label">
                                            Company
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control customInput p-2 my-3 borderForm"
                                            id="formGroupExampleInput"
                                            placeholder="Portioned Ltd"
                                        />
                                    </div>
                                    <div className="mb-3 bg-primary text-center comingBtn">
                                        <button type='submit' className="btn text-decoration-none text-white btn-padding px-4 py-3  wfc">
                                            Join the waitlist
                                        </button>
                                    </div>
                                </form>
                                <div className="social-icons d-flex align-items-center mt-5 ">
                                    <img
                                        width={28}
                                        className=""
                                        src={fb}
                                        alt=""
                                    />
                                    <img
                                        width={28}
                                        className="ms-4"
                                        src={phone}
                                        alt=""
                                    />
                                    <img
                                        width={28}
                                        className="ms-4"
                                        src={watsapp}
                                        alt=""
                                    />
                                    <img
                                        width={28}
                                        className="ms-4"
                                        src={insta}
                                        alt=""
                                    />
                                    <img
                                        width={28}
                                        className="ms-4"
                                        src={linkdin}
                                        alt=""
                                    />
                                    <img
                                        width={28}
                                        className="ms-4"
                                        src={email}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>


            <Footer />
        </>
    )
}
