import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { db } from "../Firebase";
import { useDispatch, useSelector } from "react-redux";
import { next, selectUser } from "../Features/User/UserSlice";
import Loading from "../globalcomponents/Loading";
import Portioned from "./Assets/PortionedLogo.svg";
import { BiArrowBack } from "react-icons/bi";
import { MdDone } from "react-icons/md";
import { toast } from "react-toastify";

const Summary = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state: bookingCode } = location;
  const { user } = useSelector((state) => state.user);

  const payload = JSON.parse(localStorage.getItem("payload"));

  const getUserDetails = async () => {
    const userCollection = doc(db, "user", currentUser.email);
    setLoading(true);

    try {
      const response = await getDoc(userCollection);
      const data = response.data();

      setUserData(data);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserDetails();
  }, [bookingCode]);

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    const bookingCode = Math.random()
      .toString(36)
      .substring(2, 10)
      .toUpperCase();

    try {
      await updateDoc(doc(db, "shipments", user.email), {
        shipments: arrayUnion({ ...payload, bookingCode }),
      });
      // toast.success("done");
      dispatch(
        next({
          step: "success",
        })
      );
      setTimeout(() => {
        localStorage.removeItem("payload");
      }, 1000);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const flowSteps = [
    {
      key: "deliveryOption",
      text: "Delivery option",
      depatureDate: payload?.depatureDate,
      destinationPort: payload?.destinationPort,
      description: payload?.description,
    },
    {
      key: "consignmentDetails",
      text: "Consignment details",
      category: payload?.category,
      deliveryOption: payload?.deliveryOption,
      bonded: payload?.bonded,
      hazardous: payload?.hazardous,
      packaging: payload?.packaging,
      location: payload?.location,
      worth: payload?.worth,
      size: payload?.size,
    },
    {
      key: "consignmentDocuments",
      text: "Consignment documents",
      promotionCouncilCert: payload?.img[0],
      incoporation: payload?.img[1],
      invoice: payload?.img[2],
      packingList: payload?.img[3],
    },
  ];

  // const { [bookingCode]: shippingDetails } = userData;

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="summary-page">
      {/* <div className="page-content"> */}
      <div className="shipment-header">
        <Link to="/dashboard">
          <h1
            onClick={() => {
              navigate("/dashboard");
            }}
            className="portined-logo"
          >
            PORTIONED
          </h1>
          <img src={Portioned} alt="Logo" width="50rem" />
        </Link>
        <h2>Summary</h2>
      </div>

      <div className="summary-info">
        {/* <div className="summary-info-left">
          {options.map((item) => (
            <p
              className={`shipment-link`}
              onClick={() => {
                setActiveOption(item.value);
              }}
            >
              <Link to={`/${item.name}`}>{item.value}</Link>
            </p>
          ))}
        </div> */}

        <div className="summary-info-right">
          <div className="summary-info-top">
            <div className="header-back">
              <BiArrowBack
                size={30}
                className="icon"
                onClick={() => navigate("/consignmentdocument")}
              />
              <h1>Summary</h1>
            </div>
            <p>Review your shipment information.</p>

            <div className="summary-review-container">
              {flowSteps.map((step) => {
                return (
                  <div className="summary-info-details" key={step.key}>
                    <h3 className="step-header">{step.text}</h3>
                    <p>
                      {step.category && `Category: ${step?.category || ""}`}
                    </p>
                    <p>
                      {step.depatureDate &&
                        `Departure date: ${step?.depatureDate || ""}`}
                    </p>
                    <p>
                      {step.destinationPort &&
                        `Destination Port: ${step?.destinationPort || ""}`}
                    </p>
                    <p>
                      {step.deliveryOption &&
                        `Delivery Option: ${step?.deliveryOption || ""}`}
                    </p>
                    <p>
                      {step.description &&
                        `Description: ${step?.description || ""}`}
                    </p>
                    <p>
                      {step.worth && `Worth in (Naira): ${step?.worth || ""}`}
                    </p>
                    <p>{step.size && `Size: ${step?.size || ""}`}</p>
                    <p>
                      {step.hazardous && `Hazardous: ${step?.hazardous || ""}`}
                    </p>
                    <p>{step.bonded && `Bonded: ${step?.bonded || ""}`}</p>
                    <p>
                      {step.packaging && `Packaging: ${step?.packaging || ""}`}
                    </p>
                    <p>
                      {step.location && `Location: ${step?.location || ""}`}
                    </p>

                    {step.promotionCouncilCert && (
                      <p className="order-summary">
                        Promotion Council Certificate :
                        <MdDone size={20} className="image-icons" />
                      </p>
                    )}
                    {step.invoice && (
                      <p className="order-summary">
                        Invoice :<MdDone size={20} className="image-icons" />
                      </p>
                    )}
                    {step.packingList && (
                      <p className="order-summary">
                        Packing List :
                        <MdDone size={20} className="image-icons" />
                      </p>
                    )}
                    {step.incoporation && (
                      <p className="order-summary">
                        Certificate of incoporation :
                        <MdDone size={20} className="image-icons" />
                      </p>
                    )}
                  </div>
                );
              })}
              {/* <div className="summary-info-details">
                <h2>Consignment details</h2>
                <p>{`Category: ${shippingDetails?.category || ""}`}</p>
                <p>{`Value: ${shippingDetails?.worth || ""}`}</p>
                <p>{`Size: ${shippingDetails?.size || ""}`}</p>
              </div>
              <div className="summary-info-option">
                <h2>Delivery Options</h2>
                <p>{`Pick up Location: ${shippingDetails?.location || ""}`}</p>
                <p>{`Destination: ${shippingDetails?.destination || ""}`}</p>
                <p>{`Preferred date of depature: ${
                  shippingDetails?.depaturedate || ""
                }`}</p>
              </div> */}
            </div>
          </div>
          <div className="completeBtndiv">
            <button
              className={loading ? "Loading" : "completeBtn"}
              onClick={handleSubmit}
            >
              {/* {loading ? "Loading..." : "Complete Process"} */}
              Complete Process
            </button>
          </div>
          {/* 
          <div className="summary-info-bottom">
            <div className="summary-text">
              <p>
                Our team is currently processing your information, and you can
                expect to receive a detailed quote in your email shortly. If you
                have any further questions or need immediate assistance, please
                feel free to contact us at +234 811 692 2792. Thank you for
                choosing Portioned.
              </p> */}
          {/* <Link to="/dashboard" className="summary-btn">
            Go to Dashboard
          </Link> */}
          {/* </div>
          </div> */}
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default Summary;
