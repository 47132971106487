import React from "react";
import GPS from "./Assets/GPS.svg";
import Race from "./Assets/Races.svg";
import Schedule from "./Assets/Schedule.svg";
import Sidebar from "../globalcomponents/Sidebar";

const TrackingPage = () => {
  const headerOptions = [
    { name: "current location", description: "Route-215", Img: GPS },
    { name: "ETA", description: "27 Feb 2023 11:59 Am", Img: Schedule },
    { name: "current speed", description: "80km/250'", Img: Race },
  ];

  return (
    <section className="tracking">
      <Sidebar />
      <div className="tracking-right">
        <div className="tracking-right-content">
          <div className="tracking-header">
            {headerOptions.map((item) => (
              <div className="header-option">
                <img src={item.Img} alt={item.name} />
                <p>{item.name}</p>
                <small>{item.description}</small>
              </div>
            ))}
          </div>
          <div className="tracking-footer">
            <div className="footer-header">
              <p>Consignemt</p>
              <p>Vessel/Voyage</p>
            </div>
            <div className="footer-content">
              <div className="left-footer-content">
                <p>Shipper:</p>
                <p>Consignee:</p>
                <p>Goods invoice Value declafed to to customer:</p>
                <p>Freight invoice amount:$100</p>
                <p>Bill of lading and Date:</p>
              </div>
              <div className="right-footer-content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackingPage;
