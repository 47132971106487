import Customerservice from "../pages/Assets/Customerservice.svg";
import Information from "../pages/Assets/InformationInfo.svg";
import Investigation from "../pages/Assets/Investigation.svg";
import Setting from "../pages/Assets/Setting.svg";
import userInfo from "../pages/Assets/userInfo.svg";
import Resources from "../pages/Assets/InformationResources.svg";

export const profileContent = [
  {
    name: "About Portioned",
    Img: <img src={Information} alt="Information" />,
    p1: "What is Portioned about",
    p2: "Where does Portioned operate?",
  },
  {
    name: "Features",
    Img: <img src={Setting} alt="Setting" />,
    p1: "How to get a price estimate",
    p2: "How to add a new shipment",
    p3: "How to sign in?",
    p4: "How to sign up?",
  },
  {
    name: "Help",
    Img: <img src={Customerservice} alt="Customerservice" />,
    p1: "Call us",
    p2: "Chat with us",
    p3: "Schedule a meeting in person",
  },
  {
    name: "Account and data",
    Img: <img src={userInfo} alt="userInfo" />,
    p1: "I can't sign into my account",
    p2: "Edit my account information",
    p3: "Request a copy of Portioned data",
    p4: "Delete my account",
  },
  {
    name: "Using Portioned",
    Img: <img src={Investigation} alt="Investigation" />,
    p1: "I can't add a new shipment",
    p2: "I don't trust Portioned",
    p3: "Estimated time of delivery",
    p4: "Why should I use Portioned?",
  },
  {
    name: "Resources",
    Img: <img src={Resources} alt="Resources" />,
    p1: "Take the export readiness quiz",
    p2: "Latest export/import news",
    p3: "Export document and procedures",
    p4: "Market Information",
    p5: "More",
  },
  // {
  //   name: "Legal",
  //   Img: <img src={Law} alt="Law" />,
  //   p1: "Terms and Condition",
  //   p2: "Private policy",
  //   p3: "Certifications and Licences",
  //   p4: "Partners",
  //   p4: "More",
  // },
];

export const laterPost = [
  {
    header: "Am I Ready to Export?",
    date: "17 February, 2023",
    text: [
      {
        p: "  Exporting can be an excellent opportunity for businesses looking to grow and expand their customer base beyond their local market.However, before taking the plunge, its essential to determine if you're ready to export. In this blog post, well discuss the factors that determine if a business is export-ready.",
        n1: "1 Product readiness",
        p1: " The first and most critical factor in determining export readiness  is whether your product or service is ready for the international  market. You need to ensure that your product meets international  quality and safety standards and complies with local regulations and   customs requirements. It’s also essential to research your target     market to determine if there is demand for your product and whether there is a gap in the market that your product can fill.",

        n2: "2. Financial readiness",
        p2: " Exporting can be an expensive endeavour, and it’s essential to   ensure that your business is financially ready. You’ll need to  invest in market research, product adaptation, and marketing activities. You’ll also need to consider the cost of shipping,  insurance, and any necessary customs and compliance fees. It’s crucial to have a clear understanding of your budget and projected  costs and revenue to ensure that your business can sustain exporting    in the long term.",

        n3: "  3. Organizational readiness",
        p3: "  Exporting requires a significant commitment of time and resources, and it’s essential to ensure that your business is organized and has  the necessary resources to support your export activities. You’ll need to have a dedicated team to manage your export operations and a  clear organizational structure in place to manage the additional  workload. It’s also essential to have the necessary legal and  administrative processes in place to ensure compliance with local regulations and customs requirements.",

        n4: " 4.Market readiness",
        p4: " Before exporting, it’s essential to research your target market thoroughly. You’ll need to determine if there is a demand for your product or service and whether your product is competitive in the market. You’ll also need to understand the local culture, business practices, and regulatory environment. You’ll need to have a clear strategy for entering the market, including pricing, promotion, and distribution, and understand the potential risks and challenges you  may face.",

        n5: " 5. Human resources readiness",
        p5: " Exporting requires specialized skills and knowledge, and it’s essential to ensure that your business has the necessary human resources to support your export activities. You’ll need to have a team with a clear understanding of the export process, including logistics, documentation, and compliance. You may also need to invest in additional training and development to ensure that your team has the necessary skills to support your export activities.  Exporting can be an excellent opportunity for businesses looking togrow and expand their customer base.However, before taking the plunge, it’s essential to determine if your business is export-ready.By considering the factors discussed in this blog post, you can asses your readiness and take the necessary steps to ensure a successful and sustainable export strategy.",
      },
    ],
  },
  {
    header: "  Interesting Facts About Shipping Transportation",
    date: "07 February, 2023",
    text: [
      {
        p: " Shipping transportation is a vital part of the global economy, with over 80% of global trade being transported by ships. While the shipping industry may seem mundane to some, there are many interesting facts and figures that make this industry anything but boring. In this blog post, we’ll take a look at some of the most fascinating facts about shipping transportation.",
        n1: "1. The World’s Largest Ships",
        p1: " The world’s largest ship is the Prelude, owned by Shell. This ship is longer than the Empire State Building and weighs 600,000 tonnes, making it one of the largest objects ever built by humans. The Prelude is used for offshore gas production, and it’s designed to operate in some of the harshest environments on earth",

        n2: "2. The Panama Canal",
        p2: "The Panama Canal is one of the world’s most important shipping routes, allowing ships to bypass the treacherous journey around the southern tip of South America. The canal was completed in 1914 and is over 48 miles long. The canal is an engineering marvel, with a system of locks that raises and lowers ships as they pass through the canal.",

        n3: " 3. The Suez Canal",
        p3: " The Suez Canal is another vital shipping route, connecting the Mediterranean Sea to the Red Sea. The canal is over 100 miles long and allows ships to avoid the long and dangerous journey around the southern tip of Africa.",

        n4: " 4. Containerization",
        p4: "Before containerization, cargo was loaded and unloaded by hand, which was time-consuming and inefficient. Containerization revolutionized the shipping industry by standardizing the size and shape of shipping containers. This allows for efficient loading and unloading of cargo and makes it easier to transport goods across the world.",

        n5: "5. Shipping and the Environment",
        p5: " Shipping is responsible for around 3% of global greenhouse gas emissions, making it a significant contributor to climate change. The shipping industry has taken steps to reduce emissions, with many companies investing in more fuel-efficient ships and alternative fuels such as LNG.",

        n6: "6. Pirates",
        p6: "While pirate attacks may seem like something out of a Hollywood movie, they are a very real threat to the shipping industry. The waters off the coast of Somalia are particularly dangerous, with pirates using speedboats and other tactics to attack and hijack ships.",
        n7: "7. The Benefits of Shipping",
        p7: "Shipping is the most cost-effective way to transport goods across the world, and it’s essential for the global economy. The shipping industry provides jobs for millions of people and helps to connect countries and cultures.The shipping industry may not be the most glamorous, but it’s a vital part of the global economy From the world’s largest ships to the importance of containerization, there are many fascinatinfacts about shipping transportation. While the industry faces many challenges, it’s essential fothe continued growth and development of the world economy.",
      },
    ],
  },
  {
    header: "The Wealth of Nigerian Resources",
    date: "07 March, 2023",
    text: [
      {
        p: "  Nigeria is a country blessed with an abundance of natural resources, including oil, natural gas, solid minerals, agricultural land, and a large and growing population. These resources have been a significant source of wealth for the country, contributing to its economic growth and development. In this blog post, we will explore the wealth of Nigerian resources in more detail.",
        n1: "1 Oil and Gas",
        p1: " Nigeria is one of the world’s largest oil-producing countries, with proven reserves of about 37 billion barrels. The oil industry accounts for a significant percentage of the country’s GDP and government revenue, and it provides employment opportunities for thousands of Nigerians. Nigeria is also one of the largest producers of natural gas in the world, with reserves of over 200 trillion cubic feet.",

        n2: "2. Solid Minerals",
        p2: "Nigeria is endowed with a variety of solid minerals, including coal, iron ore, tin, limestone,gold, and barite. However, the country’s solid minerals sector has been largely untapped, despite the potential for significant economic growth and diversification. The Nigerian government has taken steps to encourage investment in the solid minerals sector, and there are ongoing efforts to develop the necessary infrastructure and regulatory framework to support the sector’s growth.",

        n3: "  3. Agriculture",
        p3: " Nigeria has a vast amount of arable land, and agriculture is a significant contributor to the country’s economy. The country produces a variety of crops, including cassava, yam, rice, maize, and cocoa. The agriculture sector provides employment opportunities for millions of Nigerians, and it contributes significantly to the country’s GDP. The Nigerian government has taken steps to encourage investment in agriculture, including the establishment of the Agricultural Transformation Agenda, which seeks to modernize the sector and increase its productivity.",

        n4: " 4.Human Resources",
        p4: "Nigeria has a large and growing population, with over 200 million people. The country’s population is young and highly educated, with a significant number of young people entering the workforce every year. The country’s human resources are a significant source of wealth, with a growing pool of skilled and entrepreneurial individuals who are driving innovation and economic growth in various sectors. Nigeria is a country rich in resources, including oil, gas, solid minerals, arable land, and human resources. These resources have the potential to drive significant economic growth and development in the country, but their development requires a concerted effort from the government, private sector, and other stakeholders. As Nigeria continues to grow and develop, it is essential that its resources are harnessed sustainably, to ensure long-term economic growth and prosperity for all Nigerians.",
      },
    ],
  },
];
