import React from "react";
import { useLocation } from "react-router-dom";
import { Blogdetails1 } from "./Blogdetails1";
import { Blogdetails2 } from "./Blogdetails2";
import { Blogdetails3 } from "./Blogdetails3";

const Blogdetails = () => {
  const location = useLocation();
  const { state: text } = location;

  const renderComponent = (text) => {
    switch (text) {
      case "How to Properly Package Consignment for Ocean Freight":
        return <Blogdetails1 />;
      case "The Shipper and Government Agencies":
        return <Blogdetails2 />;
      case "Export Financing in Nigeria":
        return <Blogdetails3 />;
      default:
        return;
    }
  };

  return <div>{renderComponent(text)}</div>;
};

export default Blogdetails;
