import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../Firebase";
import { Navbar } from "../globalcomponents/Navbar";
import imageSignup from "../assets/images/Rectangle 84.png";
import { Footer } from "../globalcomponents/Footer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";

export const Signup = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const defaultValues = {
    name: "",
    phonenumber: "",
    email: "",
    password: "",
  };
  const [values, setValues] = useState(defaultValues);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { email, password, name, phonenumber } = values;

    if (name && email && password && phonenumber) {
      try {
        // create new user
        const authUser = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        // await updateProfile(authUser.user, {
        //   displayName: name,
        // });
        await setDoc(doc(db, "shipments", authUser.user.email), {
          shipments: [],
        });

        // send vefification to user email
        await sendEmailVerification(auth.currentUser);

        // store user info in the firestore database
        await setDoc(doc(db, "user", authUser.user.email), {
          ...values,
          userId: authUser.user.uid,
        });
        toast.success("Accout Created Successfully");

        setTimeout(() => {
          navigate("/emailverification");
        }, 1000);

        setLoading(false);
      } catch (error) {
        toast.error(error.messagae);
      }
    } else {
      toast.error("Please fill out the Fields");
    }
  };

  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="row LoginSection align-items-center">
          <div className="col-lg-6">
            <h3 className="main-form-title loginTitle mt-2">
              Create an account
            </h3>
            <p className="py-2">
              Let’s get started with finding you the best shipping option for
              your consignment.
            </p>
            <form onSubmit={handleSubmit} className="form mainForm loginForm">
              <div className="mb-3 mt-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  className="form-control customInput p-2 my-2 borderForm"
                  id="formGroupExampleInput"
                  placeholder="Jane Doe"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Phone Number
                </label>
                <input
                  required
                  type="phonenumber"
                  name="phonenumber"
                  className="form-control customInput p-2 my-2 borderForm"
                  id="formGroupExampleInput"
                  placeholder="+23412345678"
                  value={values.phonenumber}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 mt-3">
                <label htmlFor="formGroupExampleInput" className="form-label">
                  Email address
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  className="form-control customInput p-2 my-2 borderForm"
                  id="formGroupExampleInput"
                  placeholder="janedoe@gmail.com"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="formGroupExampleInput2" className="form-label">
                  <p>Password</p>
                </label>
                <input
                  required
                  type="password"
                  name="password"
                  className="form-control customInput p-2 my-2 borderForm"
                  id="formGroupExampleInput2"
                  placeholder="******"
                  value={values.password}
                  onChange={handleChange}
                />
              </div>
              <p>
                By clicking sign up, I acknowledge that I have read, understand
                and agree to the Portioned’s Privacy Policy and Terms of
                Service.
              </p>
              <div className="mb-3">
                <button
                  type="submit"
                  className="btn text-decoration-none text-white btn-padding px-4 py-3 bg-primary wfc"
                >
                  {loading ? "Loading..." : "Sign up"}
                </button>
              </div>
            </form>
            <p>
              Already have an account? &nbsp;
              <Link to="/login">
                <button
                  type="button"
                  className="text-decoration-none border-0 bg-transparent text-primary"
                >
                  Login
                </button>
              </Link>
            </p>
          </div>
          <div className="col-lg-6">
            <img
              className="mb-lg-0 mb-sm-3 mb-3 imgWidth"
              width=""
              src={imageSignup}
              alt=""
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
