import React from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/images/Vector (1).png";
import image2 from "../assets/images/Vector (2).png";
import watsapp from "../assets/images/tabler-icon-brand-whatsapp.png";
import insta from "../assets/images/tabler-icon-brand-instagram.png";
import linkdin from "../assets/images/tabler-icon-brand-linkedin.png";
import email from "../assets/images/tabler-icon-mail.png";
export const Footer = () => {
  return (
    <footer className="bg-primary py-5">
      <div className="mainPadding ">
        <div className="row footerMargin">
          <section className="flex-content col-lg-3 padding_1x">
            <h3 className="text-white text-decoration-none">Company</h3>
            <Link className="text-white text-decoration-none" to="/about">
              About
            </Link>
            <Link className="text-white text-decoration-none" to="/login">
              Log in
            </Link>
            <Link className="text-white text-decoration-none" to="/signup">
              Sign up
            </Link>
          </section>
          <section className="flex-content col-lg-3 padding_1x">
            <h3 className="text-white text-decoration-none">Resource</h3>
            <Link className="text-white text-decoration-none" to="/blog">
              Blog
            </Link>
            <Link className="text-white text-decoration-none" to="/faqs">
              FAQ
            </Link>
            <Link className="text-white text-decoration-none" to="/careers">
              Careers
            </Link>
          </section>
          <section className="flex-content col-lg-6 padding_1x">
            <h3 className="text-white">Newsletter</h3>
            <p className="text-white">
              Join our newsletter for regular updates. No spam ever.
            </p>
            <fieldset className="fixed_flex">
              <input
                className="newsletterInput"
                type="email"
                name="newsletter"
                placeholder="Your email address"
              />
              &nbsp;
              <button className="btn btn_2  border p-2 bg-white px-3">
                Subscribe
              </button>
            </fieldset>
            <div className="social-icons d-flex mt-3">
              <Link to="https://www.facebook.com/PortionedHQ">
                <img className="px-3" src={image1} alt="" />
              </Link>
              <Link to="tel:08116922792">
                <img className="px-3" src={image2} alt="" />
              </Link>
              <Link to="https://api.whatsapp.com/send?phone=08116922792">
                <img className="px-3" src={watsapp} alt="" />
              </Link>
              <Link to="https://www.instagram.com/PortionedHQ">
                <img className="px-3" src={insta} alt="" />
              </Link>
              <Link to="https://www.linkedin.com/company/portioned/">
                <img className="px-3" src={linkdin} alt="" />
              </Link>
              <Link>
                <img className="px-3" src={email} alt="" />
              </Link>
            </div>
          </section>
        </div>
        <div className="pt-4">
          <div className="d-flex">
            <div className="border-end">
              <Link
                className="text-white text-decoration-none my-1 pe-3"
                to="/privacypolicy"
              >
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link
                className="text-white text-decoration-none my-1 ps-3"
                to="/termscondition"
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
