import React, { useEffect } from "react";
import image from "../assets/images/blog-detail.png";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Footer } from "../globalcomponents/Footer";
import mapIcon from "../assets/images/Maps and Flags.png";
import timeIcon from "../assets/images/Three o Clock clock.png";
import printer from "../assets/images//Printer.png";
export const Termscondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />

      <section className="margin mainPadding">
        <div className="d-flex align-items-center justify-content-between">
          <div className="">
            <h3 className="main-form-title loginTitle mt-2">
              Terms and Conditions
            </h3>
            <p className="py-2">Effective day - 17 February, 2023.</p>
          </div>
          <div className="">
            <div className="d-flex align-items-center py-3">
              <img className="" src={printer} alt="" />
              <a href="#" className="text-decoration-none text-dark px-2">
                Print page
              </a>
              <p />
            </div>
          </div>
        </div>
        <div className="margin">
          <p>
            These terms and conditions ("Terms") govern your use of the freight
            forwarding technology-based services ("Services") provided by
            Portioned Technologies ("Company") in Nigeria. By using our
            Services, you agree to these Terms. If you do not agree to these
            Terms, you may not use our Services.
          </p>
          <h5>1. Description of Services</h5>
          <p>
            The Services provided by the Company enable customers to book,
            track, and manage the shipment of goods via ocean freight
            forwarding. The Company is not responsible for the physical
            transportation of goods and is not a carrier or freight forwarder.
            The Company only provides a technology platform to facilitate the
            booking and tracking of shipments.
          </p>
          <p />
          <h5>2. Use of Services</h5>
          <h5>2.1 Eligibility</h5>
          <p>
            You must be at least 18 years of age and have the legal capacity to
            enter into a binding agreement in order to use our Services.
          </p>
          <h5>2.2 Account registration</h5>
          <p>
            In order to use our Services, you must create an account with the
            Company. You are responsible for maintaining the confidentiality of
            your account information, including your password. You are also
            responsible for all activities that occur under your account.
          </p>
          <h5>2.3 Use restrictions</h5>
          <p>
            You may not use our Services for any illegal or unauthorized
            purpose. You may not modify, adapt, or hack our Services or modify
            another website or mobile application to falsely imply that it is
            associated with our Services. You may not use our Services to
            transmit any viruses, worms, or other malicious software. You may
            not use our Services to harass, abuse, or harm another person.
          </p>
          <h5>3. Payment and Refunds</h5>
          <h5>3.1 Fees</h5>
          <p>
            The fees for our Services are set forth on our website or mobile
            application. You agree to pay all fees associated with your use of
            our Services.
          </p>
          <h5> 3.2 Payment</h5>
          <p>
            You may pay for our Services using a valid credit or debit card or
            other payment methods accepted by the Company. You authorize the
            Company to charge your chosen payment method for all fees associated
            with your use of our Services.
          </p>
          <h5> 3.3 Refunds</h5>
          <p>
            All payments made for our Services are non-refundable. However, if
            you believe that you have been charged in error, please contact our
            customer service team at [insert contact information].
          </p>
          <h5>4. Intellectual Property</h5>
          <p>
            The Services provided by the Company, including all content,
            graphics, logos, and software, are the property of the Company and
            are protected by copyright and other intellectual property laws. You
            may not copy, modify, distribute, or reproduce any part of our
            Services without the Company's prior written consent.
          </p>
          <p> </p>
          <h5> 5. Confidentiality</h5>
          <p>
            {" "}
            You agree to keep confidential all information provided to you by
            the Company in connection with your use of our Services, including
            shipment details and pricing.
          </p>
          <h5> 6. Limitation of Liability</h5>
          <h5> 6.1 Disclaimer of Warranties</h5>
          <p>
            The Company provides its Services "as is" and without any warranty
            or representation of any kind, whether express or implied, including
            but not limited to the implied warranties of merchantability and
            fitness for a particular purpose.
          </p>
          <h5> 6.2 Limitation of Liability</h5>
          <p>
            To the maximum extent permitted by law, the Company shall not be
            liable for any indirect, incidental, special, or consequential
            damages, including but not limited to loss of profits, revenue, or
            data, arising out of or in connection with your use of our Services.
            In any event, the Company's liability to you for any cause
            whatsoever, and regardless of the form of the action, shall be
            limited to the amount paid by you to the Company for the Services in
            the twelve months preceding the event giving rise to the liability.
          </p>
          <h5> 7. Indemnification</h5>
          <p>
            You agree to indemnify and hold harmless the Company, its
            affiliates, and their respective officers, directors, employees, and
            agents, from and against any claims, actions, suits, or proceedings,
            as well as any losses, liabilities, damages, costs, and expenses
            (including reasonable attorneys'.
          </p>
        </div>
      </section>

      <StartShiping />
      <Footer />
    </>
  );
};
