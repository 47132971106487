import React from "react";

const UsingPortioned = () => {
  return (
    <div className="portion-feature">
      <div className="price-estimate">
        <h4> I can’t add a new shipment</h4>
        <p>
          If you're having trouble adding a new shipment on the ocean freight
          forwarding web-app 'Portioned', here's a step-by-step guide to help
          you troubleshoot the issue:
        </p>
        <p>
          1. Check your internet connection: Ensure that your internet
          connection is stable and working properly. Sometimes, a weak or
          unstable internet connection can prevent you from adding a new
          shipment.
        </p>
        <p>
          2. Check for any system alerts: Check if the app is experiencing any
          issues, and if there are any system alerts or notifications that may
          be affecting the app's functionality. This information can usually be
          found on the Portioned website or social media channels.
        </p>
        <p>
          3. Verify your account details: Make sure that your account details,
          including your contact and billing information, are up to date and
          accurate. Incorrect information can sometimes prevent you from adding
          a new shipment.
        </p>
        <p>
          4. Clear your browser cache and cookies: Clear your browser cache and
          cookies to ensure that the app is running on the latest version and
          that there are no outdated or conflicting settings.
        </p>
        <p>
          5. Contact customer support: If you've tried all the above steps and
          are still experiencing issues, contact Portioned's customer support
          team for assistance. They can help diagnose and resolve any technical
          issues you may be experiencing.
        </p>
        <p>
          If you're having difficulty adding a new shipment on the Portioned,
          there are several steps you can take to troubleshoot the issue. By
          checking your internet connection, system alerts, account details,
          clearing your browser cache and cookies, and contacting customer
          support, you can diagnose and resolve any technical issues that may be
          preventing you from adding a new shipment.
        </p>
        <h4> I don’t trust Portioned </h4>
        <p>
          If you don't trust the Portioned, here are some steps you can take to
          address your concerns:
        </p>
        <p>
          1. Verify the app's security features: Check to see if the app has
          implemented proper security measures, such as encryption, secure
          socket layer (SSL), two-factor authentication, and other measures that
          help protect user data and prevent unauthorized access.
        </p>
        <p>
          2. Check the app's privacy policy: Review the app's privacy policy to
          understand how the app collects, uses, and shares your personal
          information. Make sure that the app has a clear policy on data
          protection and does not share your information with third parties
          without your consent.
        </p>
        <p>
          3. Research the app's reputation: Conduct research to determine the
          app's reputation and see if it has received positive feedback from
          other users. You can read reviews, check ratings on app stores, and
          look for any news or reports about the app's performance and
          reliability.
        </p>
        <p>
          4. Contact customer support: If you have specific concerns or
          questions about the app's security or privacy, contact Portioned's
          customer support team. They can provide additional information and
          help address any concerns you may have.
        </p>
        <p>
          5. Consider using an alternative app: If you are still uncomfortable
          with the app or have concerns about its security or privacy, you can
          explore other freight forwarding apps that may better meet your needs
          and requirements.
        </p>
        <p>
          If you don't trust the Portioned, there are several steps you can take
          to address your concerns. By verifying the app's security features,
          checking the app's privacy policy, researching the app's reputation,
          contacting customer support, and considering using an alternative app,
          you can make an informed decision about whether or not to use the app.
        </p>
        <h4> Estimated time of delivery </h4>
        <p>
          If you need the estimated time of delivery for your shipment on the
          Portioned web-app, here are some steps you can take to get more
          information:
        </p>
        <p>
          1. Check your shipment status: Check the status of your shipment by
          clicking on the “Tracking” button in the Portioned web-app to see if
          it has been shipped or is still in transit. This information can give
          you an idea of where your shipment is and when your shipment may
          arrive.
        </p>
        <p>
          2. Contact Portioned customer support: If you have questions or
          concerns about your shipment's estimated time of delivery, contact
          Portioned's customer support team. They can provide you with
          information about your shipment's status and help you track your
          package.
        </p>
        <p>
          3. Consider shipping options: If you need your shipment to arrive at a
          specific time, consider using expedited shipping options. These
          options may come at an additional cost, but they can help ensure that
          your shipment arrives when you need it.
        </p>
        <p>
          For an estimated time of delivery for your shipment on the Portioned
          web-app, there are several steps you can take to get more information.
          By checking your shipment status, contacting Portioned customer
          support, and considering shipping options, you can get a better
          understanding of when your shipment may arrive.
        </p>
        <h4> Why should I use Portioned? </h4>
        <p>
          If you are wondering why you should use the Portioned web-app, here
          are a few reasons:
        </p>
        <p>
          1. Convenience: Portioned makes it easy to manage and track your
          shipments in one place. You can book, manage, and track your shipments
          all from the web-app, which saves you time and makes the shipping
          process more convenient.
        </p>
        <p>
          2. Cost-effective: Portioned offers competitive pricing and helps you
          save money on shipping costs. The web-app compares prices from
          different carriers and gives you the best available options based on
          your needs.
        </p>
        <p>
          3. Reliable: Portioned has a reliable network of carriers and ensures
          that your shipments are handled by experienced professionals. The
          web-app provides real-time tracking and monitoring to give you peace
          of mind and help you stay informed throughout the shipping process.
        </p>
        <p>
          4. Secure: Portioned uses advanced security measures to protect your
          data and ensure that your information is kept safe. The web-app has
          implemented SSL encryption, two-factor authentication, and other
          security features to protect your information and prevent unauthorized
          access.
        </p>
        <p>
          5. User-friendly: Portioned is designed with a user-friendly interface
          that makes it easy to navigate and use. The app provides step-by-step
          instructions and clear information to help you make informed decisions
          about your shipments.
        </p>
        <p>
          There are several reasons why you should use the Portioned web-app,
          including convenience, cost-effectiveness, reliability, security, and
          user-friendliness. If you are looking for a streamlined and efficient
          way to manage your shipments, Portioned may be a good option for you.
        </p>
      </div>
    </div>
  );
};

export default UsingPortioned;
