import React, { useEffect } from "react";
import image from "../assets/images/blog-detail.png";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Footer } from "../globalcomponents/Footer";
import mapIcon from "../assets/images/Maps and Flags.png";
import timeIcon from "../assets/images/Three o Clock clock.png";
import printer from "../assets/images/Printer.png";
export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="d-flex align-items-center justify-content-between">
          <div className="">
            <h3 className="main-form-title loginTitle mt-2">Privacy Policy</h3>
            <p className="py-2">Effective day - 17 February, 2023.</p>
          </div>
          <div className="">
            <div className="d-flex align-items-center py-3">
              <img className="" src={printer} alt="" />
              <a href="#" className="text-decoration-none text-dark px-2">
                Print page
              </a>
              <p />
            </div>
          </div>
        </div>
        <div className="margin">
          <p>
            At Portioned, we are committed to protecting your privacy and
            ensuring the security of your personal information. This privacy
            policy outlines how we collect, use, and protect your personal
            information when you use our ocean freight forwarding
            technology-based services in Nigeria.
          </p>
          <h5>Information we collect</h5>
          <p>
            We may collect the following types of personal information from you
            when you use our services:
          </p>
          <p>
            • Contact information, such as your name, email address, phone
            number, and physical address. <br />
            • Payment information, such as credit card information and bank
            account details. <br />• Shipping information, such as the origin
            and destination of your cargo, its weight and volume, and any
            special requirements or instructions. <br />• Communications with
            our customer service team, including phone calls, emails, and chat
            transcripts.
          </p>
          <h5>How we use your information</h5>
          <p>
            We use your personal information to provide you with our ocean
            freight forwarding services, including:
          </p>
          <p>
            • Processing your shipments and delivering your cargo. <br />
            • Communicating with you about your shipments and providing customer
            support. <br />
            • Managing your account and billing you for our services. <br />
            • Improving our services and developing new features. <br />•
            Complying with legal and regulatory requirements.
          </p>
          <p>
            We may also use your personal information for marketing purposes,
            such as sending you promotional emails or newsletters. You can opt
            out of these communications at any time by clicking the unsubscribe
            link in the email or contacting our customer service team.
          </p>
          <h5>How we protect your information</h5>
          <p>
            We take the security of your personal information seriously and have
            implemented appropriate measures to protect it from unauthorized
            access, use, or disclosure. These measures include:
          </p>
          <p>
            • Encrypting sensitive data, such as payment information. <br />
            • Restricting access to your personal information to authorized
            employees on a need-to-know basis. <br />• Monitoring our systems
            for unusual activity and security threats. <br />• Regularly
            reviewing and updating our security policies and procedures.
          </p>
          <p>
            We also require our partners and service providers to adhere to
            similar data protection standards and have appropriate safeguards in
            place to protect your personal information.
          </p>
          <h5>Sharing your information</h5>
          <p>
            We may share your personal information with third-party service
            providers who help us deliver our services, such as shipping
            carriers, customs brokers, and payment processors. We may also share
            your personal information with our affiliates, subsidiaries, or
            other trusted business partners.
          </p>
          <p>
            We may disclose your personal information if required by law or if
            we believe that such disclosure is necessary to protect our rights,
            property, or safety, or that of others.
          </p>
          <br />
          <h5>Your rights</h5>
          <p>
            You have certain rights regarding your personal information under
            Nigerian data protection laws. These include: <br />• The right to
            access and receive a copy of your personal information. <br />
            • The right to request that we correct or update your personal
            information. <br />• The right to request that we delete your
            personal information. <br />
            • The right to object to the processing of your personal information
            or restrict its use. <br />
            • The right to withdraw your consent to our use of your personal
            information at any time. <br />
            To exercise any of these rights, please contact our customer service
            team.
          </p>
          <h5>Changes to this policy</h5>
          <p>
            YWe may update this privacy policy from time to time to reflect
            changes in our services or legal and regulatory requirements. We
            will notify you of any material changes to this policy by posting a
            notice on our website or sending you an email.
          </p>
          <p>
            Contact us <br />
            If you have any questions or concerns about our privacy policy or
            our use of your personal information, please contact our customer
            service team at Portioned.
          </p>{" "}
          <br />
          <br />
          <h3>Cookies Policy</h3>
          <p>
            At Portioned, we use cookies and similar technologies to enhance
            your user experience and improve the performance of our ocean
            freight forwarding technology-based services in Nigeria. This
            cookies policy outlines how we use cookies on our website and mobile
            applications.
          </p>
          <h5>What are cookies?</h5>
          <p>
            Cookies are small text files that are placed on your device when you
            visit a website or use a mobile application. They enable the website
            or application to remember your preferences and provide a more
            personalized user experience.
          </p>
          <h5>Types of cookies we use</h5>
          <p>
            We use the following types of cookies on our website and mobile
            applications:
          </p>
          <p>
            Essential cookies: These cookies are necessary for the proper
            functioning of our services and cannot be disabled. They enable you
            to navigate our website or application, use its features, and access
            secure areas. <br />
            Analytical cookies: These cookies allow us to collect information
            about how you use our website or application, such as which pages
            you visit and how long you stay on each page. We use this
            information to improve our services and understand user behaviour.{" "}
            <br />
            Advertising cookies: These cookies are used to show you relevant ads
            based on your interests and browsing history. We may use third-party
            advertising networks to deliver these ads. <br />
            Social media cookies: These cookies allow you to share content from
            our website or application on social media platforms, such as
            Facebook and Twitter.
          </p>
          <h5>How we use cookies</h5>
          <p>
            We use cookies to: <br />
            Remember your preferences and settings, such as your language and
            currency preferences. <br />
            Analyze your use of our services and improve their performance.{" "}
            <br />] Personalize your user experience and show you relevant
            content and ads. <br />
            Track the effectiveness of our marketing campaigns.
          </p>
          <h5>Third-party cookies</h5>
          <p>
            We may allow third-party service providers to use cookies on our
            website and mobile applications for advertising and analytics
            purposes. These providers may include social media platforms,
            advertising networks, and analytics providers.
          </p>
          <p>
            We do not have control over these cookies and their use is subject
            to the third-party provider's privacy policies.
          </p>
          <h5>Managing cookies</h5>
          <p>
            ou can manage cookies through your browser settings or through the
            settings in our mobile applications. You can choose to block all
            cookies, accept only certain types of cookies, or be notified when a
            cookie is set.
          </p>
          <p>
            Please note that disabling cookies may affect the functionality of
            our services and your user experience. For more information on how
            to manage cookies, please refer to your browser or mobile device's
            help documentation.
          </p>
          <h5>Changes to this policy</h5>
          <p>
            We may update this cookies policy from time to time to reflect
            changes in our services or legal and regulatory requirements. We
            will notify you of any material changes to this policy by posting a
            notice on our website or sending you an email.
          </p>
          <p>
            Contact us <br />
            If you have any questions or concerns about our cookies policy,
            please contact our customer service team at Portioned.
          </p>
        </div>
      </section>

      <StartShiping />
      <Footer />
    </>
  );
};
