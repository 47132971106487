import React from "react";
import { useSelector } from "react-redux";
import DeliveryOption from "../DeliveryOptions";
import ConsignmentDetails from "../ConsignmentDetails";
import ConsignmentDocument from "../ConsignmentDocument";
import Summary from "../Summary";
import Success from "../Success/Success";

const AddShipment = () => {
  const { step } = useSelector((state) => state.user);
  const renderComponent = (step) => {
    switch (step) {
      case "deliveryOption":
        return <DeliveryOption />;
      case "consignmentDetails":
        return <ConsignmentDetails />;
      case "consignmentDocument":
        return <ConsignmentDocument />;
      case "summary":
        return <Summary />;
      case "success":
        return <Success />;
      default:
        return;
    }
  };
  return renderComponent(step);
};

export default AddShipment;
