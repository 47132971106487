import React, { useEffect } from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Footer } from "../globalcomponents/Footer";
import { useLocation } from "react-router-dom";

const Posts = () => {
  const location = useLocation();

  const { state: postDetails } = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="">
          <h3 className="main-form-title loginTitle mt-2">
            {postDetails.header}
          </h3>
          {/* <p className="py-2">18 February, 2023</p>
          <img src={image} className="w-100 my-4" alt="" /> */}

          {postDetails.text.map((post) => (
            <div>
              <p>{post?.p}</p>
              <p>{post?.n1}</p>
              <p>{post?.p1}</p>
              <p>{post?.n2}</p>
              <p>{post?.p2}</p>
              <p>{post?.n3}</p>
              <p>{post?.p3}</p>
              <p>{post?.n4}</p>
              <p>{post?.p4}</p>
              <p>{post?.n5}</p>
              <p>{post?.p5}</p>
              <p>{post?.p6}</p>
              <p>{post?.n7}</p>
              <p>{post?.p7}</p>
            </div>
          ))}
          {/* <p>
            Exporting can be an excellent opportunity for businesses looking to
            grow and expand their customer base beyond their local market.
            However, before taking the plunge, it’s essential to determine if
            you’re ready to export. In this blog post, we’ll discuss the factors
            that determine if a business is export-ready.
                      </p>
          1. Product readiness
          <p>
            The first and most critical factor in determining export readiness
            is whether your product or service is ready for the international
            market. You need to ensure that your product meets international
            quality and safety standards and complies with local regulations and
            customs requirements. It’s also essential to research your target
            market to determine if there is demand for your product and whether
            there is a gap in the market that your product can fill.
          </p>
          <br />
          2. Financial readiness
          <p>
            Exporting can be an expensive endeavour, and it’s essential to
            ensure that your business is financially ready. You’ll need to
            invest in market research, product adaptation, and marketing
            activities. You’ll also need to consider the cost of shipping,
            insurance, and any necessary customs and compliance fees. It’s
            crucial to have a clear understanding of your budget and projected
            costs and revenue to ensure that your business can sustain exporting
            in the long term.
          </p>
          <br />
          3. Organizational readiness
          <p>
            Exporting requires a significant commitment of time and resources,
            and it’s essential to ensure that your business is organized and has
            the necessary resources to support your export activities. You’ll
            need to have a dedicated team to manage your export operations and a
            clear organizational structure in place to manage the additional
            workload. It’s also essential to have the necessary legal and
            administrative processes in place to ensure compliance with local
            regulations and customs requirements.
          </p>
          <br />
          4.Market readiness
          <p>
            Before exporting, it’s essential to research your target market
            thoroughly. You’ll need to determine if there is a demand for your
            product or service and whether your product is competitive in the
            market. You’ll also need to understand the local culture, business
            practices, and regulatory environment. You’ll need to have a clear
            strategy for entering the market, including pricing, promotion, and
            distribution, and understand the potential risks and challenges you
            may face.
          </p>
          <br />
          5. Human resources readiness
          <p>
            Exporting requires specialized skills and knowledge, and it’s
            essential to ensure that your business has the necessary human
            resources to support your export activities. You’ll need to have a
            team with a clear understanding of the export process, including
            logistics, documentation, and compliance. You may also need to
            invest in additional training and development to ensure that your
            team has the necessary skills to support your export activities.
          </p>
          <br />
          Exporting can be an excellent opportunity for businesses looking to
          grow and expand their customer base. However, before taking the
          plunge, it’s essential to determine if your business is export-ready.
          By considering the factors discussed in this blog post, you can assess
          your readiness and take the necessary steps to ensure a successful and
          sustainable export strategy.
          <br />
          <br /> */}
        </div>
      </section>
      <StartShiping />
      <Footer />
    </>
  );
};

export default Posts;
