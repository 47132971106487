import React, { useEffect, useState } from "react";
import moment from "moment";
import Sidebar from "../globalcomponents/Sidebar";
import { Footer } from "../globalcomponents/Footer";
import { useSelector } from "react-redux";
import { selectUser } from "../Features/User/UserSlice";
import Navbar from "../components/Dashboard/Navbar";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../Firebase";
import Loading from "../globalcomponents/Loading";
import { toast } from "react-toastify";

const History = () => {
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrder] = useState([]);
  const [userData, setUserData] = useState([]);
  const currentUser = useSelector(selectUser);

  const getUserDetails = async () => {
    const shipmentCollection = doc(db, "shipments", currentUser.email);
    const userCollection = doc(db, "user", currentUser.email);
    try {
      setLoading(true);

      const response = await getDoc(shipmentCollection);
      const resp = await getDoc(userCollection);
      setUserData(resp.data());

      const data = response.data();

      const { shipments } = data;
      const sortedArray = shipments?.sort(
        (a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)
      );

      setOrder(sortedArray);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const date = moment(orders.depaturedate).format("DD - MMM");

  const handleDelete = async (id) => {
    const shipmentCollection = doc(db, "shipments", currentUser.email);

    try {
      const result = orders.filter((order) => order.bookingCode !== id);
      await updateDoc(shipmentCollection, {
        shipments: result,
      });
      await getUserDetails();
      toast.success("Successfully deleted");
    } catch (error) {
      toast.error("Something went wrong");
    }

    setLoading(false);
  };

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="tracking">
      <div className="tracking-main">
        <Sidebar sidebar={sidebar} toggleSidebar={toggleSidebar} />
        <div
          className={
            orders.length < 1 ? "history-right full-screen" : "history-right"
          }
        >
          <Navbar userData={userData} toggleSidebar={toggleSidebar} />

          <div className="history-right-content">
            {orders?.length < 1 ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <h1>No history yet</h1>
              </div>
            ) : (
              orders?.map((order) => {
                return (
                  <div className="history-details" key={order.bookingCode}>
                    <div className="history-info">
                      <p>{`Booking number :${order.bookingCode}`}</p>
                      <button
                        className={
                          order.madePayment ? "transit-btn" : "pending-payment"
                        }
                      >
                        {order.madePayment ? "In transit" : "Pending payment"}
                      </button>
                    </div>
                    <small>{order.category}</small>
                    <div className="delivery-info">
                      <div className="delivery-date">
                        <small> {date}</small>
                        <small> :{order.location}</small>
                      </div>
                      <div className="delivery-destination">
                        <small>
                          {order?.deliveryDate ? order?.deliveryDate : "N/A"}
                        </small>
                        <small> {order.destinationPort}</small>
                      </div>
                    </div>
                    <button
                      onClick={() => handleDelete(order.bookingCode)}
                      className="history-delete"
                    >
                      Delete
                    </button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default History;
