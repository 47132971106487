import React from "react";
import { AiFillPhone } from "react-icons/ai";
import { AiFillWechat } from "react-icons/ai";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <div className="more-info info">
      <div className="info-container">
        <AiFillPhone size={40} />
        <p className="">Call us on 08116922792</p>
        <Link to="tel:08116922792" className="talk-to-us">
          Call
        </Link>
      </div>
      <div className="info-container">
        <AiFillWechat size={40} />
        <p>Chat with us </p>
        <Link
          to="https://api.whatsapp.com/send?phone=08116922792"
          className="talk-to-us"
        >
          Chat
        </Link>
      </div>
    </div>
  );
};

export default Help;
