import React from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { Hero } from "../components/Home/Hero";
import { InstantQuotes } from "../components/Home/InstantQuotes";
import { WhyChoose } from "../components/Home/WhyChoose";
import { PortionedWorks } from "../components/Home/PortionedWorks";
import { Faqs } from "../components/Home/Faqs";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Footer } from "../globalcomponents/Footer";
export const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <InstantQuotes />
      <WhyChoose />
      <PortionedWorks />
      <Faqs />
      <StartShiping />
      <Footer />
    </>
  );
};
