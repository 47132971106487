import React from "react";
import { Navbar } from "../globalcomponents/Navbar";
import image from "../assets/images/2989096 1.png";
import { Footer } from "../globalcomponents/Footer";
import { useNavigate } from "react-router-dom";

export const EmailVerified = () => {
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <Navbar />

      <section className="margin" style={{ paddingBottom: "2rem" }}>
        <div className="container">
          <div className="row LoginSection align-items-center">
            <div className="col-lg-6">
              <h3 className="main-form-title loginTitle mt-2">
                Email verified
              </h3>
              <p className="py-2">Your email has been verified.</p>
              <div className="btn-padding ">
                <button
                  onClick={goToDashboard}
                  className="btn text-decoration-none text-white px-4 py-3 bg-primary"
                >
                  Go to dashboard
                </button>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="mb-lg-0 mb-sm-3 mb-3 imgWidth"
                width="75%"
                src={image}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
