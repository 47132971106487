import React from "react";

const Resources = () => {
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <h1>Coming soon</h1>
    </div>
  );
};

export default Resources;
