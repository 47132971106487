import React from 'react'
import { Navbar } from '../globalcomponents/Navbar'
import { Hero } from '../components/Home/Hero'
import imageSignup from '../assets/images/Rectangle 84.png'
import { Footer } from '../globalcomponents/Footer'
import { Link } from 'react-router-dom'
import image1 from '../assets/images/5500661 1.png'
export const PasswordReset = () => {
    return (
        <>
            <Navbar />

            <section className="margin">
                <div className="container">
                    <div className="row LoginSection align-items-center">
                        <div className="col-lg-6">
                            <h3 className="main-form-title loginTitle mt-2">All done!</h3>
                            <p className="py-2">
                                Your password has been reset. Please, log in to continue.
                            </p>
                            <div className="btn-padding ">
                                <Link
                                    to='/login'
                                    className="btn text-decoration-none text-white px-4 py-3 bg-primary"
                                >
                                    Log in
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img
                                className="mb-lg-0 mb-sm-3 mb-3 imgWidth"
                                width="75%"
                                src={image1}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
