import React, { useEffect } from "react";
import image from "../assets/images/blog-detail.png";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Logos } from "../globalcomponents/Logos";
import { Footer } from "../globalcomponents/Footer";
import mapIcon from "../assets/images/Maps and Flags.png";
import timeIcon from "../assets/images/Three o Clock clock.png";
export const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="">
          <h3 className="main-form-title loginTitle mt-2">Careers</h3>
          <p className="py-2">We are not currently hiring at the moment</p>
          {/* <h4 className="my-2">Be part of our mission!</h4> */}
          {/* <div className=" border-bottom py-4">
            <div className="d-flex justify-content-between ">
              <div>
                <h5>Product Designer</h5>
                <p>Product Designer</p>
              </div>
              <div>
                <h6>Apply</h6>
              </div>
            </div>
            <div className="d-flex pt-4">
              <button className="btn border d-flex align-items-center py-2 px-4 ">
                <img className="px-1" src={mapIcon} alt="" />
                Product Designer
              </button>
              <button className="btn border d-flex align-items-center py-2 mx-3 px-4">
                <img className="px-1" src={timeIcon} alt="" />
                Full time
              </button>
            </div>
          </div> */}
          {/* <div className=" border-bottom py-4">
            <div className="d-flex justify-content-between ">
              <div>
                <h5>Product Designer</h5>
                <p>Product Designer</p>
              </div>
              <div>
                <h6>Apply</h6>
              </div>
            </div>
            <div className="d-flex pt-4">
              <button className="btn border d-flex align-items-center py-2 px-4 ">
                <img className="px-1" src={mapIcon} alt="" />
                Product Designer
              </button>
              <button className="btn border d-flex align-items-center py-2 mx-3 px-4">
                <img className="px-1" src={timeIcon} alt="" />
                Full time
              </button>
            </div>
          </div> */}
          {/* <div className=" border-bottom py-4">
            <div className="d-flex justify-content-between ">
              <div>
                <h5>Product Designer</h5>
                <p>Product Designer</p>
              </div>
              <div>
                <h6>Apply</h6>
              </div>
            </div>
            <div className="d-flex pt-4">
              <button className="btn border d-flex align-items-center py-2 px-4 ">
                <img className="px-1" src={mapIcon} alt="" />
                Product Designer
              </button>
              <button className="btn border d-flex align-items-center py-2 mx-3 px-4">
                <img className="px-1" src={timeIcon} alt="" />
                Full time
              </button>
            </div>
          </div> */}
          {/* <div className=" border-bottom py-4">
            <div className="d-flex justify-content-between ">
              <div>
                <h5>Product Designer</h5>
                <p>Product Designer</p>
              </div>
              <div>
                <h6>Apply</h6>
              </div>
            </div>
            <div className="d-flex pt-4">
              <button className="btn border d-flex align-items-center py-2 px-4 ">
                <img className="px-1" src={mapIcon} alt="" />
                Product Designer
              </button>
              <button className="btn border d-flex align-items-center py-2 mx-3 px-4">
                <img className="px-1" src={timeIcon} alt="" />
                Full time
              </button>
            </div>
          </div> */}
          {/* <div className=" border-bottom py-4">
            <div className="d-flex justify-content-between ">
              <div>
                <h5>Product Designer</h5>
                <p>Product Designer</p>
              </div>
              <div>
                <h6>Apply</h6>
              </div>
            </div>
            <div className="d-flex pt-4">
              <button className="btn border d-flex align-items-center py-2 px-4 ">
                <img className="px-1" src={mapIcon} alt="" />
                Product Designer
              </button>
              <button className="btn border d-flex align-items-center py-2 mx-3 px-4">
                <img className="px-1" src={timeIcon} alt="" />
                Full time
              </button>
            </div>
          </div> */}
        </div>
      </section>

      <StartShiping />
      <Footer />
    </>
  );
};
