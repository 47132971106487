import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase";
import { Navbar } from "../globalcomponents/Navbar";
import { Footer } from "../globalcomponents/Footer";
import image1 from "../assets/images/Left arrow.png";
import image2 from "../assets/images/2760417 1.png";
import { useSelector } from "react-redux";
import { selectUser } from "../Features/User/UserSlice";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { toast } from "react-toastify";
export const EmailVerification = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRedirect = () => {
    window.location.href = `https://www.${user?.email.split("@")[1]}`;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && currentUser.emailVerified) {
        // setIsEmailVerified(true);
        navigate("/emailverified");
      }
    });

    return unsubscribe;
  }, [navigate]);

  const handleResend = async () => {
    const res = await sendEmailVerification(auth.currentUser);

    toast.success("Email Verification sent successfully");
  };

  return (
    <>
      <Navbar />
      <section className="margin">
        <div className="container">
          <div className="row LoginSection align-items-center">
            <div className="col-lg-6">
              <h3 className="main-form-title loginTitle mt-2">
                Check your email
              </h3>
              <p className="py-2 px-2">
                {` We sent a verification link to  `}
                <a
                  to={` ${user?.email}`}
                  className="text-decoration-none text-dark"
                >
                  {user?.email}
                </a>
              </p>
              <div className="btn-padding ">
                <button
                  onClick={handleRedirect}
                  className="btn text-decoration-none text-white px-4 py-3 bg-primary"
                >
                  Go to mail
                </button>
              </div>
              <p className="py-3">
                Didn’t receive the email?
                <a
                  className="text-decoration-none text-dark cursor-pointer px-2"
                  onClick={handleResend}
                >
                  Click to resend.
                </a>
              </p>
              <div className="d-flex align-items-center">
                <img className="" src={image1} alt="" />
                <a
                  className="text-decoration-none text-dark px-2 cursor-pointer px-2"
                  onClick={handleResend}
                >
                  Click to resend.
                </a>
                <p />
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="mb-lg-0 mb-sm-3 mb-3 imgWidth"
                width="75%"
                src={image2}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
