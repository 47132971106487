import React, { useEffect } from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Logos } from "../globalcomponents/Logos";
import { Footer } from "../globalcomponents/Footer";
import { BlogCard } from "../components/Blog/BlogCard";
import { LaterPosts } from "../components/Blog/LaterPosts";
export const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="">
          <h3 className="main-form-title loginTitle mt-2">Blog</h3>
          <p className="py-2">
            The latest news and relevant industry information.
          </p>
        </div>
        <div className="margin">
          <h4 className="main-form-title loginTitle mt-2">Recent posts</h4>
          <div className="container1">
            <div className="row mt-5">
              <BlogCard />
            </div>
          </div>
        </div>
        <div className="margin">
          <LaterPosts />
        </div>
      </section>

      <StartShiping />
      <Footer />
    </>
  );
};
