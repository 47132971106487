import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut, deleteUser } from "firebase/auth";
import { toast } from "react-toastify";
import { auth, db } from "../Firebase";
import Sidebar from "../globalcomponents/Sidebar";
import rightarrow from "./Assets/rightarrow.svg";
import Exit from "./Assets/Exit.svg";
import Bin from "./Assets/Bin.svg";
import {
  // getUserDetails,
  removeUserFromLocalStorage,
} from "../Utils/UtilityFunctions";
import { useSelector } from "react-redux";
import { selectUser } from "../Features/User/UserSlice";
import Loading from "../globalcomponents/Loading";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Navbar from "../components/Dashboard/Navbar";
import { Footer } from "../globalcomponents/Footer";

const Edit = () => {
  const [userData, setUserData] = useState([]);
  const defaultValues = {
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const currentUser = useSelector(selectUser);
  const [values, setValues] = useState(defaultValues);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const getUserDetails = async () => {
    const userCollection = doc(db, "user", currentUser.email);

    try {
      setLoading(true);

      const response = await getDoc(userCollection);
      const data = response.data();

      setUserData(data);
      setValues(data);
      setLoading(false);
    } catch (error) {
      toast.error("There was an error");
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { firstname, lastname, phonenumber, email } = values;

    if (firstname || lastname || email || phonenumber) {
      try {
        await updateDoc(doc(db, "user", currentUser.email), {
          ...values,
        });

        await getUserDetails();

        toast.success("Profile was updated Successfully");
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Logged out Successfully");

      removeUserFromLocalStorage();

      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      const { message } = error;
      toast.success({ message });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      user && (await deleteUser(user));

      toast.success("User Deleted Successfully");
      removeUserFromLocalStorage();

      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      toast.error("Somthing went wrong, please login and try again");
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="tracking">
      <div className="tracking-main">
        <Sidebar sidebar={sidebar} toggleSidebar={toggleSidebar} />
        <div className="edit-right">
          {/* <div className="edit-right-content"> */}
          <Navbar userData={userData} toggleSidebar={toggleSidebar} />

          {/* <div className="history-menu edit-nav">
          <button onClick={toggleSidebar}>
            <img src={Menu} alt="Menu" width="40px" />
          </button>
          <h6>Edit Account</h6>
        </div> */}
          <div className="edit-form">
            <form onSubmit={handleUpdate} className="shipment-form-input">
              <label htmlFor="firstname">First Name</label>
              <input
                className="shipment-input"
                type="text"
                name="name"
                required
                onChange={handleChange}
                placeholder="Jane"
                value={values.name}
              />
              <label htmlFor="lastname">Last Name</label>
              <input
                className="shipment-input"
                type="text"
                name="lastname"
                required
                onChange={handleChange}
                placeholder="Doe"
                value={values.lastname}
              />
              <label htmlFor="phonenumber">Phone Number</label>
              <input
                className="shipment-input"
                type="text"
                name="phonenumber"
                required
                onChange={handleChange}
                placeholder="+123456789"
                value={values.phonenumber}
              />
              <label htmlFor="email">Email Address</label>
              <input
                className="shipment-input"
                type="email"
                name="email"
                required
                onChange={handleChange}
                placeholder="janedoe@gmail.com"
                value={values.email}
              />
              <button
                type="submit"
                // onClick={handleUpdate}
                className="submit-btn"
              >
                {loading ? "Loading..." : "Save"}
              </button>
            </form>

            <div onClick={handleLogout} className="log-out">
              <p id="logout">Log out</p>
              <img src={Exit} alt="Exit" />
            </div>
            <div onClick={handleDeleteAccount} className="delete-account">
              <p id="delete">Delete account</p>
              <img src={Bin} alt="Delete Account" />
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Edit;
