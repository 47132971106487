import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserCircle, FaCaretDown } from "react-icons/fa";
import { RiMenu2Line } from "react-icons/ri";
import { signOut } from "firebase/auth";
import { removeUserFromLocalStorage } from "../../Utils/UtilityFunctions";
import { auth, db } from "../../Firebase";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Navbar = ({ userData, toggleSidebar }) => {
  const [showLogout, setShowLogout] = useState(false);
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Logged out Successfully");
      removeUserFromLocalStorage();

      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      const { message } = error;
      toast.success({ message });
    }
  };
  return (
    <div className="right-content-header">
      <button onClick={toggleSidebar} className="menu-logo">
        <RiMenu2Line style={{ fontWeight: "1000", color: "blue" }} size={40} />
        {/* <img src={Menu} alt="Menu" width="40px" /> */}
      </button>
      <div className="dashboard-nav">
        <button
          type="button"
          className="nav-button"
          onClick={() => setShowLogout(!showLogout)}
        >
          <FaUserCircle />
          {userData?.name || user.name}
          <FaCaretDown />
        </button>

        <div className={showLogout ? "dropdown show-dropdown" : "dropdown "}>
          <button type="button" className="dropdown-btn" onClick={handleLogout}>
            logout
          </button>
        </div>
        {/* <h6
          onClick={() => navigate("/profile")}
        >{`Welcome back ${userData.name}`}</h6>
        <small>{date.toDateString()}</small> */}
      </div>
    </div>
  );
};

export default Navbar;
