import React from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { Hero } from "../components/Home/Hero";
import imageSignup from "../assets/images/Rectangle 84.png";
import { Footer } from "../globalcomponents/Footer";
import { StartShiping } from "../globalcomponents/StartShiping";

export const NoPage = () => {
  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <p>404 error</p>
        <h3>Page not found</h3>
        <p>
          Sorry, the page you are looking for does not exist or has been moved.
          Try searching our site.
        </p>
        <fieldset className="fixed_flex">
          <div className="row flex-nowrap">
            <div className="col-lg-4 col-sm-8 col-8">
              {" "}
              <input
                className="newsletterInput w-100 form-control h-100"
                type="email"
                name="newsletter"
                placeholder="Search our site"
              />
            </div>
            <div className="col-lg-2 col-sm-2 col-2">
              <button className="btn btn_2  border border-2 border-primary p-2 bg-white px-4">
                Search
              </button>
            </div>
          </div>
        </fieldset>
      </section>
      <StartShiping />
      <Footer />
    </>
  );
};
