import React, { useEffect, useState } from "react";
import "./ProfileStyle.css";
import { useNavigate } from "react-router-dom";
import { profileContent } from "../../Utils/ProfileDate";
import Sidebar from "../../globalcomponents/Sidebar";
import { useSelector } from "react-redux";
import { selectUser } from "../../Features/User/UserSlice";
import { Footer } from "../../globalcomponents/Footer";
import Navbar from "../../components/Dashboard/Navbar";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import Loading from "../../globalcomponents/Loading";
import { toast } from "react-toastify";

const Profile = () => {
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const currentUser = useSelector(selectUser);

  const navigate = useNavigate();

  const getUserDetails = async () => {
    const userCollection = doc(db, "user", currentUser.email);

    try {
      setLoading(true);

      const response = await getDoc(userCollection);
      const data = response.data();

      setUserData(data);
      setLoading(false);
    } catch (error) {
      toast.error("There was an error");
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleView = (id) => {
    navigate(`/moreinfo`, { state: id });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="tracking">
      <div className="tracking-main">
        <Sidebar sidebar={sidebar} toggleSidebar={toggleSidebar} />
        <div className="profile-right">
          <div className="profile-right-content">
            <Navbar userData={userData} toggleSidebar={toggleSidebar} />
            {/* <div className="profile-header">
              <div className="header-nav">
                <button onClick={toggleSidebar}>
                  <img src={Menu} alt="Menu" />
                </button>
                <h6>{`Hello ${user.name}`}</h6>
              </div>
              <Link to="/edit">Edit profile</Link>
            </div> */}
            <div className="profile-options">
              {profileContent.map((item) => (
                <div className="profile-options-container" key={item.name}>
                  <div
                    className="profile-options-heading"
                    onClick={() => handleView(item.name)}
                  >
                    <p>{item?.Img}</p>
                    <h4>{item?.name}</h4>
                  </div>
                  <div
                    className="profile-options-content"
                    onClick={() => handleView(item.name)}
                  >
                    <p>{item?.p1}</p>
                    <p>{item?.p2}</p>
                    <p>{item?.p3}</p>
                    <p>{item?.p4}</p>
                    <p>{item?.p5}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Profile;
