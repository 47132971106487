import React from 'react'

export const PortionedWorks = () => {
  return (
    <section className="margin mainPadding">
    <h1 className="chooseTitle text-center mb-5">How Portioned works</h1>
    <div className="row px-3 px-sm-3 px-lg-0  m-0 partitioned">
      <div className="col-lg-3 mb-lg-0 mb-4 mb-sm-4">
        <div className="bg-primary h-100 p-lg-5 p-sm-3 p-3 text-white primarySection">
          <h2 className="mainSteps">STEP 1:</h2>
          <p className="chooseCont">Add a shipment</p>
          <p className="mt-4 chooseCont">
            Input all the required details on the Portioned app or website.
          </p>
        </div>
      </div>
      <div className="col-lg-3 mb-lg-0 mb-4 mb-sm-4">
        <div className="bg-primary h-100 p-lg-5 p-sm-3 p-3   text-white primarySection">
          <h2 className="mainSteps">STEP 2:</h2>
          <p className="chooseCont">Select shipment option</p>
          <p className="mt-4 chooseCont">
            Select the shipping option that matches your needs and provide the
            required and necessary information.
          </p>
        </div>
      </div>
      <div className="col-lg-3 mb-lg-0 mb-4 mb-sm-4">
        <div className="bg-primary h-100 p-lg-5 p-sm-3 p-3  text-white primarySection">
          <h2 className="mainSteps">STEP 3:</h2>
          <p className="chooseCont">Make payment</p>
          <p className="mt-4 chooseCont">
            Make the necessary payments, Portioned team will send you the
            necessary details in your support inbox.
          </p>
        </div>
      </div>
      <div className="col-lg-3 mb-lg-0 mb-4 mb-sm-4">
        <div className="bg-primary h-100 p-lg-5 p-sm-3 p-3 text-white primarySection">
          <h2 className="mainSteps">STEP 4:</h2>
          <p className="chooseCont">Live Tracking</p>
          <p className="mt-4 chooseCont">
            Sit back and track the entire movement of your consignment to the
            destination port!
          </p>
        </div>
      </div>
    </div>
  </section>
  

  )
}
