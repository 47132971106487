import React from "react";

const AccountData = () => {
  return (
    <div className="portion-feature">
      <div className="price-estimate">
        <h4> I can’t sign into my account </h4>
        <p>
          If you're having trouble signing into your account on 'Portioned',
          here's a step-by-step guide to help you troubleshoot the issue:
        </p>
        <p>
          1. Check your internet connection: Ensure that you have a stable
          internet connection, as a weak or unstable connection may cause login
          issues.
        </p>
        <p>
          2. Check your login details: Double-check that you're entering the
          correct email address and password associated with your Portioned
          account. If you've forgotten your password, you can reset it by
          clicking on the "Forgot Password" button on the login page.
        </p>
        <p>
          3. Clear your browser cache: Clearing your browser cache can help fix
          login issues. Go to your browser settings, select "History," and click
          on "Clear Browsing Data." Select the time range and check the box for
          "Cached images and files," then click on "Clear Data."
        </p>
        <p>
          4. Try a different browser: If you're still having issues, try logging
          in using a different browser or device to see if the issue is
          browser-specific.
        </p>
        <p>
          5. Contact customer support: If none of the above steps resolve the
          issue, reach out to Portioned customer support for assistance. You can
          find the support contact information on the Portioned website or
          within the web-app.
        </p>
        <p>
          If you're unable to sign into your account on the Portioned, start by
          checking your internet connection and login details. If that doesn't
          work, clear your browser cache, try a different browser, or contact
          customer support for assistance. By following these steps, you can
          troubleshoot the issue and regain access to your account.
        </p>
        <h4>Edit my account information</h4>
        <p>
          If you need to edit your account information on 'Portioned', here's a
          step-by-step guide to help you do so:
        </p>
        <p>
          1. Log in to your account: Go to the Portioned website and log in to
          your account using your email address and password.
        </p>
        <p>
          2. Go to the "Account" page: Once you're logged in, click on the
          "Account" button located in the top right-hand corner of the screen.
        </p>
        <p>
          3. Edit your account information: On the "Account" page, click on the
          “Edit Account” button on the right side, you can edit your account
          information, such as your name, email address, phone number, and
          company details. Simply click on the field you want to edit and make
          the necessary changes.
        </p>
        <p>
          4. Save your changes: Once you've made your edits, click on the "Save
          Changes" button located at the bottom of the page to save your updated
          account information.
        </p>
        <p>
          5. Verify your changes: Check to make sure that your changes have been
          saved and that your account information is accurate and up-to-date.
        </p>
        <p>
          In conclusion, editing your account information on the Portioned ocean
          freight forwarding web-app is a simple process that involves logging
          in to your account, going to the "Settings" page, editing your account
          information, saving your changes, and verifying that your updates have
          been saved. By following these steps, you can ensure that your account
          information is accurate and up-to-date, which can help you manage your
          logistics and supply chain needs more effectively.
        </p>
        <h4> Request my Portioned data</h4>
        <p>
          If you would like to request a copy of your data from Portioned,
          here's a step-by-step guide to help you do so:
        </p>
        <p>
          1. Log in to your account: Go to the Portioned website and log in to
          your account using your email address and password.
        </p>
        <p>
          2. Go to the "Account" page: Once you're logged in, click on the
          "Account" button located in the top left column of the screen.
        </p>
        <p>
          3. Click on "Request my data": Scroll down the "Account" page and
          click on the "Request my data" button.
        </p>
        <p>
          4. Confirm your request: A pop-up message will appear, asking you to
          confirm your request. Click "Confirm" to proceed.
        </p>
        <p>
          5. Check your email: Portioned will send you an email with a link to
          download your data. This email will contain a unique link that will
          allow you to access and download your data.
        </p>
        <p>
          6. Download your data: Click on the link in the email to access and
          download your data. The data will be provided in a machine-readable
          format, such as a CSV file.
        </p>
        <h4>Delete my account</h4>
        <p>
          If you want to delete your account on Portioned, here's a step-by-step
          guide to help you do so:
        </p>
        <p>
          1. Log in to your account: Go to the Portioned website and log in to
          your account using your email address and password.
        </p>
        <p>
          2. Go to the "Account" page: Once you're logged in, click on the
          "Account" button located in the top right-hand corner of the screen.
        </p>
        <p>
          3. Click on "Delete my account": Click on the “Edit account” button on
          the right side and scroll down the "Edit account" page and click on
          the "Delete my account" button.
        </p>
        <p>
          4. Confirm your request: A pop-up message will appear, asking you to
          confirm your request. Click "Confirm" to proceed.
        </p>
        <p>
          5. Enter your password: You will be prompted to enter your password to
          confirm your identity.
        </p>
        <p>
          6. Choose a reason for deleting your account: You will be asked to
          select a reason for why you want to delete your account.
        </p>
        <p>
          7. Submit your request: Once you've entered your password and selected
          a reason for deleting your account, click the "Delete my account"
          button to submit your request.
        </p>
        <p>
          {" "}
          8. Verify your account has been deleted: Check to make sure that your
          account has been deleted by attempting to log in again. You should
          receive an error message indicating that your account no longer
          exists.
        </p>
      </div>
    </div>
  );
};

export default AccountData;
