import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser } from "./Features/User/UserSlice";
import "../src/assets/css/index.css";
import { Home } from "./pages/Home";
import { Blog } from "./pages/Blog";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Blogdetails from "./pages/BlogCard/Blogdetails";
import { Careers } from "./pages/Careers";
import { ComingSoon } from "./pages/ComingSoon";
import { Signup } from "./pages/Signup";
import { EmailVerification } from "./pages/EmailVerification";
import { Login } from "./pages/Login";
import { EmailVerified } from "./pages/EmailVerified";
import { ForgotPassword } from "./pages/ForgotPassword";
import { NewPassword } from "./pages/NewPassword";
import { PasswordReset } from "./pages/PasswordReset";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Termscondition } from "./pages/Termscondition";
import { Faqs } from "./pages/Faqs";
import { About } from "./pages/About";
import { Resources } from "./pages/Resources";
import { NoPage } from "./pages/NoPage";
import { Blogdetails1 } from "./pages/BlogCard/Blogdetails1";
import { Blogdetails2 } from "./pages/BlogCard/Blogdetails2";
import { Blogdetails3 } from "./pages/BlogCard/Blogdetails3";

import TrackingPage from "./pages/TrackingPage";
import History from "./pages/History";
import Profile from "./pages/ProfilePage/Profile";
import Edit from "./pages/Edit";
import Payment from "./pages/Payment";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Posts from "./pages/Posts";
import MoreInfo from "./pages/ProfilePage/Features/MoreInfo";
import AddShipment from "./pages/AddShipmentPage/AddShipment";
// import './App.scss';
function App() {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  useEffect(() => {
    const unSuscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        dispatch(
          login({
            uuid: currentUser.uid,
            email: currentUser.email,
          })
        );
      } else {
        dispatch(logout());
      }
    });

    return () => {
      unSuscribe();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdetails1" element={<Blogdetails1 />} />
        <Route path="/blogdetails2" element={<Blogdetails2 />} />
        <Route path="/blogdetails3" element={<Blogdetails3 />} />
        <Route path="/blogdetails" element={<Blogdetails />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tracking"
          element={
            <ProtectedRoute>
              <TrackingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/history"
          element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit"
          element={
            <ProtectedRoute>
              <Edit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />

        <Route path="/emailverification" element={<EmailVerification />} />
        <Route path="/addshipment" element={<AddShipment />} />
        <Route path="/emailverified" element={<EmailVerified />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/termscondition" element={<Termscondition />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/about" element={<About />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/moreinfo" element={<MoreInfo />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
}

export default App;
