import React from "react";

export const Faqs = () => {
  return (
    <section className="margin">
      <h1 className="chooseTitle text-center">Frequently asked questions</h1>
      <div className="mainPadding">
        <div
          className="accordion accordion-flush mt-3"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed py-4 accordianTitle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                How do you handle customs clearance?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Portioned will handle customs clearance on behalf of the
                shipper, including preparing the necessary documents and paying
                any applicable duties or taxes..
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed py-4 accordianTitle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                How do you ensure the safety and security of my shipment?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                How do you ensure the safety and security of my shipment?
                Portioned works with carriers and logistics providers who have
                established procedures for ensuring the safety and security of
                shipments. This includes tracking and monitoring the shipment
                throughout the shipping process, as well as using secure
                facilities for warehousing and distribution.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed py-4 accordianTitle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                How do you determine the cost of shipping?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                How do you determine the cost of shipping? The cost of shipping
                is determined by a variety of factors, including the type of
                goods being shipped, the weight and volume of the shipment, the
                shipping destination, and the mode of transportation. We will
                provide a quote based on these factors.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
