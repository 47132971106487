import React from 'react'
import image from '../../assets/images/bwink_tsp_01_single_11 1.png'
export const InstantQuotes = () => {
  return (
<section className="mainPadding mb-5">
  <div className="row align-items-center">
    <div className="col-lg-6">
      <h3 className="main-form-title">Get instant quote</h3>
      <form className="form mainForm" action="#">
        <div className="mb-3 mt-3">
          <label htmlfor="formGroupExampleInput" className="form-label">
            Port of origin
          </label>
          <input
            type="text"
            className="form-control customInput p-2 my-4 borderForm"
            id="formGroupExampleInput"
            placeholder="Tin can "
          />
        </div>
        <div className="mb-3">
          <label htmlfor="formGroupExampleInput2" className="form-label">
            Port of destination
          </label>
          <input
            type="text"
            className="form-control customInput p-2 my-4 borderForm"
            id="formGroupExampleInput2"
            placeholder="Rotterdam"
          />
        </div>
        <div className="mb-3">
          <label htmlfor="formGroupExampleInput2" className="form-label">
            Weight (kg)
          </label>
          <input
            type="text"
            className="form-control customInput p-2 my-4 borderForm"
            id="formGroupExampleInput2"
            placeholder="{400}"
          />
        </div>
        <div className="mb-3 ">
          <button className="btn text-decoration-none  text-white btn-padding px-4 py-3 bg-primary wfc">
            Get quote
          </button>
        </div>
      </form>
    </div>
    <div className="col-lg-6">
      <img
        className="w-lg-auto w-100 w-sm-100"
        src={image}
        alt=""
      />
    </div>
  </div>
</section>


  )
}
