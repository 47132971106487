import React from "react";

const AboutPortioned = () => {
  return (
    <div className="portion-feature">
      <div className="price-estimate">
        <h4>What is Portioned about?</h4>
        <p>
          Portioned takes the stress and hassles of exporting or importing
          consignments off the shipper and interacts with every player (shipping
          companies, regulatory bodies, pre-shipment agencies, external bodies)
          in the shipping chain on behalf of them. We drive visibility in every
          chain of shipping processes.
        </p>
        {/* <h4> Where is Portioned head office?</h4>
        <p> The head office of Portioned is located at...</p> */}
        <h4>Where does Portioned operate?</h4>
        <p>
          Portioned operates in Nigeria, but our freight services spreads
          worldwide. Click on “Shipping Rates” on your dashboard to see our
          frequent routes.
        </p>
      </div>
    </div>
  );
};

export default AboutPortioned;
