import React, { useEffect } from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { Footer } from "../globalcomponents/Footer";
import vision from "../assets/images/20943559 1.png";
import mission from "../assets/images/20943509 1.png";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="">
          <h3 className="main-form-title loginTitle mt-2">Overview</h3>
          <p className="py-2 light">
            Portioned takes the stress and hassles of exporting or importing
            consignments off the shipper and interacts with every player
            (shipping companies, regulatory bodies, pre-shipment agencies,
            external bodies) in the shipping chain on behalf of the client. We
            drive visibility in every chain of shipping processes.
          </p>
        </div>
        <div className="bg-primary aboutMain p-5 margin">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3">
                <h3 className="text-white aboutTitle">20+</h3>
                <p className="text-white">Trusted clients</p>
              </div>
              <div className="col-lg-3">
                <h3 className="text-white aboutTitle">2023</h3>
                <p className="text-white">Founded</p>
              </div>
              <div className="col-lg-3 ">
                <h3 className="text-white aboutTitle">40+</h3>
                <p className="text-white">Fulfilled deliveries</p>
              </div>
            </div>
          </div>
        </div>
        <div className="margin">
          <div className="row ">
            <div className="col-lg-6 aboutSection d-flex flex-column text-end">
              <img width="60%" src={vision} className="ms-auto" alt="" />
              <h4 className="p-2">Vision</h4>
              <p className="p-2 content light">
                {" "}
                To drive visibility in every chain of the Nigerian shipping
                procedure. Portioned aims to eliminate the stress of the shipper
                by manoeuvring common setbacks, mistakes and delays, erasing
                losses, and the general stress involved in shipping in Nigeria.
              </p>
            </div>
            <div className="col-lg-6">
              <img width="56%" src={mission} alt="" />
              <h4 className="p-2">Mission</h4>
              <p className="p-2 light">
                With the uncertainty of the industry and shippers facing
                constant hiccups, Portioned provides a solutions that eliminates
                the problems in a quick and timely manner. With the visibility
                Portioned offers, businesses are able to get their goods across
                to any markets worldwide.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="margin">
                    <h4 className="main-form-title loginTitle text-center mt-2">
                        Our Leadership
                    </h4>
                    <div className="mt-5 row">
                        <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                            <img src={about1} className="w-100" alt="" />
                            <h3 className=" loginTitle mt-3">Covey</h3>
                            <p className=" light">Chief Executive Officer</p>
                        </div>
                        <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                            <img src={about2} className="w-100" alt="" />
                            <h3 className=" loginTitle mt-3">Charles</h3>
                            <p className=" light">Chief Operations Officer</p>
                        </div>
                        <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                            <img src={about3} className="w-100" alt="" />
                            <h3 className=" loginTitle mt-3">Winner</h3>
                            <p className=" light">Chief Financial Officer</p>
                        </div>
                    </div>
                    <div className="mt-5 row">
                        <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                            <img src={about4} className="w-100" alt="" />
                            <h3 className=" loginTitle mt-3">Emmanuel</h3>
                            <p className=" light">Chief Compliance Officer</p>
                        </div>
                        <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                            <img src={about5} className="w-100" alt="" />
                            <h3 className=" loginTitle mt-3">Joshua</h3>
                            <p className=" light">Chief Compliance Officer</p>
                        </div>
                        <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                            <img src={about6} className="w-100" alt="" />
                            <h3 className=" loginTitle mt-3">Obianuju</h3>
                            <p className=" light">Chief People Officer</p>
                        </div>
                    </div>
                </div> */}
      </section>
      {/* <JoinedthePortioned /> */}
      <StartShiping />
      <Footer />
    </>
  );
};
