import React, { useEffect, useState } from "react";
import Sidebar from "../globalcomponents/Sidebar";
import { Footer } from "../globalcomponents/Footer";
import Loading from "../globalcomponents/Loading";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { useSelector } from "react-redux";
import { selectUser } from "../Features/User/UserSlice";
import { toast } from "react-toastify";
import Navbar from "../components/Dashboard/Navbar";
import Table from "../components/Table/Table";

const Payment = () => {
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userShipment, setUserShipment] = useState([]);
  const [userData, setUserData] = useState([]);
  const currentUser = useSelector(selectUser);

  const getUserDetails = async () => {
    const userCollection = doc(db, "user", currentUser.email);
    const shipmentCollection = doc(db, "shipments", currentUser.email);

    try {
      setLoading(true);

      const response = await getDoc(shipmentCollection);
      const resp = await getDoc(userCollection);
      setUserData(resp.data());

      const { shipments } = response.data();

      setUserShipment(shipments);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="tracking">
      <div className="tracking-main">
        <Sidebar sidebar={sidebar} toggleSidebar={toggleSidebar} />
        <div className="payment-right">
          <Navbar userData={userData} toggleSidebar={toggleSidebar} />
          <div className="payment-right-content">
            <div
            // style={{ display: "grid", placeItems: "center", height: "100%" }}
            >
              {userShipment?.length < 1 ? (
                <h3 style={{ textAlign: "center" }}>No Data yet </h3>
              ) : (
                <Table tableData={userShipment} />
              )}
            </div>
            {/* <div className="payment-billing-history">
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Payment;
