import React from "react";
import { Link } from "react-router-dom";

export const JoinedthePortioned = () => {
  return (
    <section className="margin mainPadding">
      <div className="mainPadding joiedpor py-5 shippingBtn">
        <h3 className="shippingTitle mb-2   ">Join the Portioned team!</h3>
        <p>
          Are you passionate about creating a better environment for exports in
          Nigeria? Then join us!
        </p>
        <div className=" btn-padding btnMargin pt-3 mb-5">
          <Link
            to="/login"
            className="text-decoration-none mb-5 bg-primary text-white shippingBtn"
          >
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
};
