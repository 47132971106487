import React from "react";
import { Navbar } from "../globalcomponents/Navbar";
import { StartShiping } from "../globalcomponents/StartShiping";
import { JoinedthePortioned } from "../globalcomponents/JoinedthePortioned";
import { Footer } from "../globalcomponents/Footer";
import blog1 from "../assets/images/blog-1.png";
import blog2 from "../assets/images/blog-2.png";
import blog3 from "../assets/images/blog-3.png";
export const Resources = () => {
  return (
    <>
      <Navbar />
      <section className="margin mainPadding">
        <div className="">
          <h2 className="main-form-title loginTitle mt-2">Resources</h2>
          <p className="py-2 light">
            Learn about everything, from our frequently asked questions, to the
            latest news and relevant industry information.
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <h4 className="bolder">Blog</h4>
          <p>
            <a href="#" className="text-decoration-none text-primary">
              See all
            </a>
          </p>
        </div>
        <div className="margin">
          <div className="mt-5 row">
            <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
              <div className="card">
                <img src={blog1} className="w-100" alt="..." />
                <div className="card-img-overlay d-flex flex-column justify-content-center p-5">
                  <h3 className="card-text resourceTitle text-white">
                    Understanding shipping terms.
                  </h3>
                  <div className="btn-padding">
                    <a
                      href="#myservices"
                      className="btn text-decoration-none bg-white text-black text-left py-3 px-4"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
              <div className="card">
                <img src={blog2} className="w-100" alt="" />
                <div className="card-img-overlay d-flex flex-column justify-content-center p-5">
                  <h3 className="card-text text-white resourceTitle">
                    Understanding shipping terms.
                  </h3>
                  <div className="btn-padding">
                    <a
                      href="#myservices"
                      className="btn text-decoration-none bg-white text-black text-left py-3 px-4"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
              <div className="card">
                <img src={blog3} className="w-100" alt="" />
                <div className="card-img-overlay d-flex flex-column justify-content-center p-5">
                  <h3 className="card-text text-white resourceTitle">
                    Understanding shipping terms.
                  </h3>
                  <div className="btn-padding">
                    <a
                      href="#myservices"
                      className="btn text-decoration-none bg-white text-black text-left py-3 px-4"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="margin bgLight borderRadius p-3 p-lg-5 p-sm-3">
            <h4 className="bolder">Frequently Asked Questions</h4>
            <p className="light resourceContent mb-0">
              Everything you need to know about Portioned. Can’t find the answer
              you’re looking for? Please chat with our friendly team.
            </p>
            <div className="row mt-5">
              <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4 p-2">
                <h5>Why should I use Portioned?</h5>
                <p className="light">
                  Portioned offers complete and reliable visible tracking of all
                  consignments. Say goodbye to manual tracking of your cargo. We
                  handle all the steps of getting your consignment from the
                  warehouse to its destination country.
                </p>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                <h5>How can I track my consignment?</h5>
                <p className="light">
                  Click on the ‘Track my shipment’ icon on the homepage and
                  input your tracking number. You will get all the necessary
                  information about your consignment status.
                </p>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                <h5>How do I obtain certifications?</h5>
                <p className="light">
                  To learn more about the specific import requirements of your
                  consignment, including all necessary documentation and the
                  different routes of compliance, please contact our team for a
                  tailored guide.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4 p-2">
                <h5>Does Portioned insure my consignment?</h5>
                <p className="light">
                  Please, read our Terms and Conditions. Also, one of our
                  mandated policies is that our clients are required to buy
                  cargo insurance to protect their consignments in transit. It
                  is in our best interest to ensure our client's consignments
                  are well protected.
                </p>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                <h5>What is your refund policy?</h5>
                <p className="light">
                  We do not offer any refunds for our services. However, we can
                  negotiate a refund from the consignment carrier on behalf of
                  our esteemed customers. The refund policies of these carriers
                  may however vary.
                </p>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-sm-4 mb-4">
                <h5>Where do you ship to?</h5>
                <p className="light">
                  We ship worldwide. We are partners with the most esteemed
                  shipping lines in the Country, and distance or location is
                  never a barrier for us. With Portioned, your consignments can
                  reach the ends of the world.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between bg-white resourceLast p-3 p-lg-4 p-sm-2 borderRadius">
              <div>
                <h5>Still have questions?</h5>
                <p className="light">Chat with our friendly team</p>
              </div>
              <div className="btn-padding">
                <a
                  href="#myservices"
                  className="btn text-decoration-none border border-dark bg-white text-black text-left py-3 px-4"
                >
                  Get in touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <JoinedthePortioned />
      <StartShiping />
      <Footer />
    </>
  );
};
